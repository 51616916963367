<ul class="nav navbar-nav navbar-right">
  <li><a routerLink="/home">Home</a></li>
  <!--<li><a routerLink="/companies">Companies</a></li>-->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Corporates
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="dropdown-item" href="/mindberry-for-work">MindBerry for Work</a>
      <a class="dropdown-item" href="/mindberry-for-business">MindBerry for Business</a>
    </div>
  </li>
  <li><a routerLink="/professionals">Professionals</a></li>
  <li><a routerLink="/about">About Us</a></li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Log In
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="dropdown-item" href="https://pro.mind-berry.com/login" target="_blank">I am a Professional</a>
      <a class="dropdown-item" href="https://client.mind-berry.com/login" target="_blank">I am a Client</a>
    </div>
  </li>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Sign Up
    </a>
    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="dropdown-item" href="https://pro.mind-berry.com/signup" target="_blank">Register as a Professional</a>
      <a class="dropdown-item" href="https://client.mind-berry.com/signup" target="_blank">Register as a Client</a>
    </div>
  </li>
</ul>
