<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>Account Sign Up</h1>
        <p>Please select which account you would like to sign up for</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/banner-signup.png" />
    </div>
  </div>
</div>

<section class="">
  <div class="container">
    <div class="col-sm-6">
      <div class="ad-sec ad-bg-1-alt">
        <div class="col-md-5 col-sm-12 mobile-centred-txt">
          <h1>Already a <br />Client?</h1>

          <br />

          <a class="btn-main" type="button" href="https://client.mind-berry.com" target="_blank">Log In</a>
        </div>

        <br />

        <div class="col-sm-12 mobile-centred-txt">
          <h2>Not yet registered?</h2>
          <a class="btn-simple" href="https://client.mind-berry.com/signup" target="_blank">Register now</a>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="ad-sec ad-bg-2-alt">
        <div class="col-md-5 col-sm-12 mobile-centred-txt">
          <h1>Already a <br />Professional?</h1>

          <br />

          <a class="btn-main" type="button" href="https://pro.mind-berry.com" target="_blank">Log In</a>
        </div>

        <br />

        <div class="col-sm-12 mobile-centred-txt">
          <h2>Not yet registered?</h2>
          <a class="btn-simple" href="https://pro.mind-berry.com/signup" target="_blank">Register now</a>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>
