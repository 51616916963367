<div class="banner-blog blog-header">
  <div class="container">
    <div class="blog-banner-content">
      <a class="btn btn-main" routerLink="/articles">Back to articles</a>
    </div>
  </div>
</div>

<div class="post-body-wrapper">
  <h1>
    This is the Title of the post. its quite long <br />
    <span>12/12/2021</span>
  </h1>

  <img src="https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />

  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis rutrum leo ut risus pharetra, vel tristique mauris dignissim. Nullam at quam elit. Suspendisse eget ligula tincidunt, congue justo ac, lacinia velit. Sed tempus sem ut urna volutpat, sit amet sodales odio volutpat. Mauris dui velit, porttitor in lectus iaculis, faucibus eleifend ex. Nullam commodo, augue eu viverra elementum, orci justo pharetra metus, in hendrerit magna turpis eu felis. Nunc a sagittis lectus. Sed nec mattis leo. Etiam quis lacus nec mauris accumsan faucibus dictum vitae erat.
  </p>
  <p>
    Aliquam accumsan fermentum leo, nec accumsan odio tristique vitae. Sed nec sem ac tellus bibendum malesuada. Praesent porta erat finibus tellus varius, sed tristique arcu tempor. Nam gravida pellentesque imperdiet. Maecenas pretium elit sed congue condimentum. Cras semper mi et arcu auctor suscipit. Mauris laoreet dictum purus eget faucibus. In lectus metus, luctus vitae egestas non, aliquet sit amet velit. Proin sed magna tellus. Nam auctor, nisl eget efficitur tristique, turpis nisi molestie turpis, accumsan feugiat nulla nisi non nisi.
  </p>
  <p>
    Curabitur ut diam eu turpis aliquet tincidunt vel ac risus. Suspendisse imperdiet convallis ligula, eu sagittis ligula luctus id. Maecenas nec porta metus. Vivamus tempus ante eu condimentum finibus. Sed quis dui consequat, gravida massa non, condimentum ex. Integer eu massa vestibulum, tempor diam in, vestibulum justo. Mauris placerat nulla sit amet dolor aliquet placerat. Donec fringilla condimentum convallis. Curabitur eget lacus ac nisi tempus sollicitudin. Donec malesuada tempor congue. Cras eu nisi justo. In eget luctus turpis. Nullam tempus nisl felis, non malesuada nunc dapibus quis.
  </p>
</div>
