import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';





//App
import { AppComponent } from './app.component';

//Layouts
import { MainLayoutComponent } from './components/layouts/mainlayout/mainlayout.component';
import { SecondaryLayoutComponent } from './components/layouts/secondarylayout/secondarylayout.component';
import { ErrorLayoutComponent } from './components/layouts/errorlayout/errorlayout.component';
import { BlogLayoutComponent } from './components/layouts/bloglayout/bloglayout.component';
import { HollandBarretLayoutComponent } from './components/layouts/hollandandbarretlayout/hollandandbarretlayout.component';

//Error Pages
import { ErrorPageNotFoundComponent } from './components/errors/pagenotfound/error-page-not-found.component';

//Main Page
import { HomeComponent } from './components/home/home.component';

//Secondary Pages
import { CompaniesComponent } from './components/companies/companies.component';
import { ProfessionalsComponent } from './components/professionals/professionals.component';
import { MyAccountComponent } from './components/myaccount/myaccount.component';
import { AboutComponent } from './components/about/about.component';
import { ForWorkComponent } from './components/corporate/for-work/forwork.component';
import { ForBusinessComponent } from './components/corporate/for-business/forbusiness.component';
import { ArticleComponent } from './components/article/article.component';
import { BusinessFormComponent } from './components/business-form/business-form.component';

//Shared
import { MenuMainComponent } from './components/shared/menu/menu.component';
import { MenuItemsComponent } from './components/shared/menuitems/menuitems.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { TermsComponent } from './components/legal/termsofuse/terms.component';
import { PrivacyPolicyComponent } from './components/legal/privacypolicy/privacypolicy.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AffiliateComponent } from './components/shared/affiliate/affiliate.component';

//Services
import { ContactService } from './services/contact.service';
import { ProfileResolve } from './resolvers/profile.resolve';
import { CounsellorService } from './services/counsellor.service';
import { DirectoryComponent } from './components/directory/directory.component';
import { EthicalStandardsComponent } from './components/ethical-standards/ethical-standards.component';
import { EmergencyContactsComponent } from './components/emergency-contacts/emergency-contacts.component';
import { NHSComponent } from './components/nhs/nhs.component';

//Pipes
import { NewlinePipe } from './pipes/formatnewlines.pipe';
import { SessionTypePipe } from './pipes/sessiontype.pipe';
import { DurationPipe } from './pipes/duration.pipe';

//Blogs
import { BlogIndexComponent } from './components/blogindex/blogindex.component';
import { BlogPostComponent } from './components/blogpost/blogpost.component';

//Blog Posts
import { BlogPostProcrastinationComponent } from './components/blogpost-collection/procrastination.component';
import { BlogPostStartupMentalHealthComponent } from './components/blogpost-collection/startup-mental-health.component';
import { BlogPostWorkplaceBurnoutComponent } from './components/blogpost-collection/workplace-burnout.component';

//Holland and Barret Pages
import { HollandAndBarretComponent } from './components/hollandandbarret/hollandandbarret.component';


@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    SecondaryLayoutComponent,
    BlogLayoutComponent,
    ErrorLayoutComponent,
    HollandBarretLayoutComponent,
    ErrorPageNotFoundComponent,
    MenuMainComponent,
    MenuItemsComponent,
    FooterComponent,
    HomeComponent,
    CompaniesComponent,
    ProfessionalsComponent,
    MyAccountComponent,
    ContactComponent,
    TermsComponent,
    AboutComponent,
    PrivacyPolicyComponent,
    ProfileComponent,
    DirectoryComponent,
    NHSComponent,
    ForWorkComponent,
    ForBusinessComponent,
    ArticleComponent,
    BusinessFormComponent,
    EthicalStandardsComponent,
    EmergencyContactsComponent,
    AffiliateComponent,
    BlogIndexComponent,
    BlogPostComponent,
    BlogPostProcrastinationComponent,
    BlogPostStartupMentalHealthComponent,
    BlogPostWorkplaceBurnoutComponent,
    HollandAndBarretComponent,
    NewlinePipe,
    SessionTypePipe,
    DurationPipe,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },

      //Main Layout routing
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: 'home',
            component: HomeComponent
          }
        ],
      },
      //Secondary Layout routing
      {
        path: '',
        component: SecondaryLayoutComponent,
        children: [
          {
            path: 'companies',
            component: CompaniesComponent
          },
          {
            path: 'about',
            component: AboutComponent
          },
          {
            path: 'professionals',
            component: ProfessionalsComponent,
          },
          {
            path: 'myaccount',
            component: MyAccountComponent,
          },
          {
            path: 'contact',
            component: ContactComponent,
          },
          {
            path: 'terms-of-use',
            component: TermsComponent,
          },
          {
            path: 'privacy-policy',
            component: PrivacyPolicyComponent,
          },
          {
            path: 'profile/:mbno', component: ProfileComponent,
            resolve: {
              profile: ProfileResolve
            }
          },
          {
            path: 'directory',
            component: DirectoryComponent
          },
          {
            path: 'nhs',
            component: NHSComponent
          },
          {
            path: 'mindberry-for-work',
            component: ForWorkComponent
          },
          {
            path: 'mindberry-for-business',
            component: ForBusinessComponent
          },
          {
            path: 'ethical-standards',
            component: EthicalStandardsComponent
          },
          {
            path: 'emergency-contacts',
            component: EmergencyContactsComponent
          },
          {
            path: 'covid-19',
            component: ArticleComponent
          },
          {
            path: 'business-form',
            component: BusinessFormComponent
          }
        ],
      },
      //Blog Layout routing
      {
        path: '',
        component: BlogLayoutComponent,
        children: [
          {
            path: 'HIDDENarticles',
            component: BlogIndexComponent
          },
          {
            path: 'HIDDENarticle-post',
            component: BlogPostComponent
          },
          {
            path: 'articles/what-is-procrastination',
            component: BlogPostProcrastinationComponent
          },
          {
            path: 'articles/startup-founders-and-mental-health',
            component: BlogPostStartupMentalHealthComponent
          },
          {
            path: 'articles/workplace-burnout',
            component: BlogPostWorkplaceBurnoutComponent
          },
        ]
      },
      //Holland and Barret Layout routing
      {
        path: '',
        component: HollandBarretLayoutComponent,
        children: [
          {
            path: 'hollandandbarrett',
            component: HollandAndBarretComponent
          }
        ]
      },

      //Error Layout routing
      {
        path: '',
        component: ErrorLayoutComponent,
        children: [
          { path: '**', component: ErrorPageNotFoundComponent }
        ]
      }
    ])
  ],
  providers: [
    ContactService,
    CounsellorService,
    ProfileResolve
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
