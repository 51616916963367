import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'for-work',
  templateUrl: './forwork.component.html',
})
export class ForWorkComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Workplace Mental Health Services & Resources | MindBerry');
    this.meta.addTag({ name: 'description', content: 'Improve the mental health and wellbeing of your workplace with MindBerry. Easy & affordable to access counselling and coaching, anywhere at anytime.' });
    this.meta.addTag({ name: 'keywords', content: 'Learning platform, Coaching for business, Executive coaching, Mental health at work, Counselling at work, Business psychology, Organisational psychology, Mental health platform, Coaching platform' });

    window.scrollTo(0, 0);
  }
}
