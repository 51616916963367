<div class="afil-bg">
  <div class="container">
    <div class="afil-sec-wrapper">
      <div><a href="https://www.bacp.co.uk/" target="_blank"><img src="/images/mb-afil-bacp.png"></a></div>
      <div><a href="https://www.ebay.co.uk/" target="_blank"><img src="/images/mb-afil-ebay.png"></a></div>
      <div><a href="https://www.munichre.com/" target="_blank"><img src="/images/mb-afil-munichre.png"></a></div>
      <div><a href="https://ico.org.uk/" target="_blank"><img src="/images/mb-afil-ico.png"></a></div>
      <div><a href="https://www.inmotionventures.com/" target="_blank"><img src="/images/mb-afil-inmotion.png"></a></div>
      <div><a href="https://www.learningtosleep.se/" target="_blank"><img src="/images/mb-afil-learnsleep.png"></a></div>
      <div><a href="https://www.thunes.com/" target="_blank"><img src="/images/mb-afil-thunes.png"></a></div>
      <div><a href="https://urban.co/en-gb/" target="_blank"><img src="/images/mb-afil-urban.png"></a></div>
      <div><a href="https://www.hekahappy.com/blog/why-heka-is-the-best-online-wellness-benefit-to-engage-your-remote-team" target="_blank"><img src="/images/mb-afil-heka.png"></a></div>
      <div><a href="https://www.coffeeand.tv/" target="_blank"><img src="/images/mb-afil-coffeetv.png"></a></div>
      <div><a href="https://www.tysonblake.com/" target="_blank"><img src="/images/mb-afil-tyson-and-blake.png"></a></div>
      <div><a href="https://www.virginstartup.org/" target="_blank"><img src="/images/mb-afil-virgin.png"></a></div>
      <div><a href="https://wotch.io/" target="_blank"><img src="/images/mb-afil-wotch.png"></a></div>
      <div><a href="https://yogiyoga.co.uk" target="_blank"><img src="/images/mb-afil-yogiyoga.png"></a></div>
      <div><a href="https://www.vodafone.co.uk/mobile/vodafone-techstarter" target="_blank"><img src="/images/mb-afil-vodafone.png"></a></div>
    </div>
  </div>
</div>
