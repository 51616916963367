<div class="banner-alt hb-banner">
  <div class="container">
    <div class="hb-logo-sec">
      <img src="/images/logo-main.png" />
    </div>
  </div>

  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">

      <div class="banner-content">
        <h1>Your mind matters</h1>
        <p>One-to-one coaching and counselling with qualified and experienced professionals. <br />Anytime, anywhere.</p>
      </div>
      <div class="hb-banner-btn-sec">
        <button class="btn-main" type="button" (click)="scrollToElement(target)">SIGN UP</button>
      </div>
    </div>

    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/banner-home.png" />
    </div>
  </div>
</div>

<div class="clear"></div>

<section>
  <div class="container">
    <div class="col-sm-4">
      <div class="content">
        <h1>Proactively work towards your best-self</h1>
        <p>
          Whether you want to work on your mental health or develop new mental skills that will help you thrive at work
          and in your relationships, MindBerry has a professional for you.
        </p>
      </div>

      <div class="icon-sec col-md-10 col-sm-12 nopadding">
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12"><img src="/images/hp-icons-1.png" /></div>
          <div class="">Mental Health</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12"><img src="/images/hp-icons-2.png" /></div>
          <div class="">Life Coaching</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12"><img src="/images/hp-icons-3.png" /></div>
          <div class="">Executive Coaching</div>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-7 col-sm-offset-1 sec-2-img">
      <img src="/images/hp-sec-1.png" />
    </div>
  </div>
</section>

<section class="sec-3">
  <div class="container">
    <div class="">
      <h1>How it works</h1>
    </div>

    <div class="col-sm-6">
      <div class="">
        <img src="/images/sec-3-1.png" />
      </div>

      <div class="sec-inner">
        <h2>Search and find</h2>

        <p>
          Search for coaching or mental health professionals who are right for you based on your needs and preferences.
        </p>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="">
        <img src="/images/sec-3-2.png" />
      </div>

      <div class="sec-inner">
        <h2>Connect and communicate</h2>

        <p>
          Book and attend your sessions on your phone or computer using our communication tools.
        </p>
      </div>
    </div>

    <div class="clear"></div>
  </div>

  <div class="container">
    <div class="hb-form-wrapper" #target>
      <form [formGroup]="myForm">
        <!-- HB Form page 1 -->
        <div *ngIf="hbFormStep === 1 && !isProcessed">
          <div>
            <h1>Start working on your mental wellbeing today.</h1>
            <h2>Fill out this quick form and receive a free 25 minute credit for your first session</h2>
          </div>
          <button class="btn-main" type="button" (click)="hbFormStatus(2)">Start</button>
        </div>

        <!-- HB Form page 2 -->
        <div *ngIf="hbFormStep === 2 && !isProcessed">
          <div>
            <label for="name">Firstly, what is your name:</label>
            <input type="text" id="name" [formControl]="myForm.controls['name']" [(ngModel)]="model.name" required/>
            <div class="form-error-sec" *ngIf="!myForm.controls['name'].valid && myForm.controls['name'].touched">Please provide your name</div>
          </div>
          <button class="btn-main" type="button" (click)="hbFormStatus(3)" [disabled]="!myForm.controls['name'].valid">Next</button>
        </div>

        <!-- HB Form page 3 -->
        <div *ngIf="hbFormStep === 3 && !isProcessed">
          <div>
            <label for="email">Please give us your email address:</label>
            <input type="text" id="email" [formControl]="myForm.controls['email']" [(ngModel)]="model.email" required/>
            <div class="form-error-sec" *ngIf="!myForm.controls['email'].valid && myForm.controls['email'].touched">Please provide you email address</div>
          </div>
          <button class="btn-main" type="button" (click)="submitHBForm()" [disabled]="!myForm.controls['email'].valid">Submit</button>
        </div>

        <!-- HB Form page 4 Success -->
        <div *ngIf="isProcessed">
          <h1>Thank you for your Interest</h1>
          <h2>Our team will be in contact with you shortly.</h2>
        </div>
      </form>
    </div>
  </div>

  <div class="clear"></div>
</section>

<div class="clear"></div>

<affiliate></affiliate>

<div class="hb-footer">
  <p>
    MindBerry
  </p>
</div>
