import { Pipe, PipeTransform } from '@angular/core';
import { SessionDuration } from '../models/SessionDuration';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {

    transform(value: number, args: string[]): any {
        switch (value) {
            case SessionDuration.duration25Mins:
                return '25 Minutes';
            case SessionDuration.duration50Mins:
                return '50 Minutes';
            case SessionDuration.duration80Mins:
                return '80 Minutes';
            default:
                return 'N/A';
        }
    }

}