<div class="banner-main-alt company-banner">
  <div class="comp-ban-alt">
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 nopadding">
        <div class="banner-content">
          <h1>Improve the productivity and wellbeing of your organisation</h1>
          <br />
          <p>Connect your team with the best coaching and mental health professionals with MindBerry</p>
          <br />
        </div>
        <div class="banner-btn">
          <button class="btn-main" type="button" routerLink="/contact">TALK TO US ABOUT A TRIAL</button>
        </div>
      </div>
      <div class="col-lg-6 col-md-5 col-sm-5">
        <img src="" />
      </div>
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="comp-sec-1-title col-lg-6 col-md-8 col-sm-10 col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
      <div class="content">
        <h1>Reduce your risk and <br />costs with MindBerry</h1>
        <p>
          Poor wellbeing and mental health cost your organisation
        </p>
      </div>
    </div>

    <div class="clear"></div>

    <br />

    <div class="col-lg-6 col-md-8 col-sm-10 col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
      <div class="comp-vid">
        <iframe width="480" height="385" src="https://www.youtube-nocookie.com/embed/0AslnkDJA2I?controls=1&rel=0&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>

    <br />

    <div class="clear"></div>

    <div class="col-sm-10 col-sm-offset-1 comp-sec-1-outer">
      <div class="icon-sec-left pro-sec-2-icon-sec">
        <div class="col-sm-4 comp-sec-1">
          <div class="col-sm-12 nopadding"><img src="/images/comp-icons-1.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile small-text">
            91 million workdays are lost due to mental illness
          </div>
        </div>
        <div class="col-sm-4 comp-sec-1">
          <div class="col-sm-12 nopadding"><img src="/images/comp-icons-2.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile small-text">
            The average cost of replacing a team member is £30,000
          </div>
        </div>
        <div class="col-sm-4 comp-sec-1">
          <div class="col-sm-12 nopadding"><img src="/images/comp-icons-3.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile small-text">
            The average cost of poor mental health is £1,481 per employee
          </div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>

<section class="comp-sec-2-outer">
  <div class="container">
    <h1>How it works</h1>
    <p class="alt-p">
      Secure, flexible and scalable
    </p>

    <br />

    <div class="comp-sec-3">
      <div class="col-sm-12">
        <div class="col-lg-4 col-md-5 col-sm-5">
          <img src="/images/comp-img-1.png" />
          <h2>Trial</h2>
          <p>
            We allow a number of key people within your organisation to experience MindBerry
          </p>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="col-lg-4 col-md-5 col-sm-5 col-lg-offset-8 col-md-offset-7 col-sm-offset-7">
          <img src="/images/comp-img-2.png" />
          <h2>Pilot</h2>
          <p>
            Once you understand the benefits of MindBerry, we expand the scope of your trial period at a reduced cost
          </p>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="col-lg-4 col-md-5 col-sm-5">
          <img src="/images/comp-img-3.png" />
          <h2>Onboarding</h2>
          <p>
            You give us the emails of the people you want to onboard onto the system
            (users only sign up if they want to)
          </p>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="col-lg-4 col-md-5 col-sm-5 col-lg-offset-8 col-md-offset-7 col-sm-offset-7">
          <img src="/images/comp-img-4.png" />
          <h2>Team roll-out</h2>
          <p>
            Users create an account and then search for the coach or mental health professional who is right for them based on their needs
          </p>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="col-lg-4 col-md-5 col-sm-5">
          <img src="/images/comp-img-5.png" />
          <h2>Sessions start</h2>
          <p>
            Users book their sessions via a built-in calendar and have their sessions online
          </p>
        </div>
      </div>

      <div class="clear"></div>
    </div>
  </div>

  <br />

  <div class="container">
    <div class="comp-last-sec">
      <div class="content">
        <h1>When your people grow, <br />your business grows</h1>

        <button class="btn-main" type="button" routerLink="/contact">TALK TO US ABOUT A TRIAL</button>
      </div>

      <div class="icon-sec-left pro-sec-2-icon-sec col-sm-6 col-sm-offset-3 nopadding">
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12 nopadding"><img src="/images/hp-icons-1.png" /></div>
          <div class="col-sm-12 push-down-1 nopadding-mobile">Mental <br />Health</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12 nopadding"><img src="/images/hp-icons-2.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile">Life <br />Coaching</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12 nopadding"><img src="/images/hp-icons-3.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile">Executive <br />Coaching</div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>
