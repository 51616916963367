<div class="banner-main home-banner">
  <div class="container">
    <div class="">

      <div class="banner-content">
        <h1>Your mind matters</h1>
        <p>One-to-one coaching and counselling with qualified and experienced professionals. <br />Anytime, anywhere.</p>

        <button class="btn-main" type="button" routerLink="/myaccount">SIGN UP</button>
      </div>

      <div class="banner-content2">
        <p>Free support for <img class="nhs-logo" src="/images/nhs-banner-logo.jpg" style="height: 25px; width: auto;"/> frontline staff
        </p>
        <div style="clear: left;"></div>

        <button class="btn-ban-nhs" type="button" routerLink="/nhs">REGISTER TODAY</button>
      </div>

    </div>

    <div class="">
      <img src="/images/2021/hero2021.svg" />
    </div>
  </div>
</div>

<div class="clear"></div>

<section>
  <div class="container">
    <div class="col-sm-4">
      <div class="content">
        <h1>Proactively work towards your best-self</h1>
        <p>
          Whether you want to work on your mental health or develop new mental skills that will help you thrive at work
          and in your relationships, MindBerry has a professional for you.
        </p>
      </div>

      <div class="icon-sec col-md-10 col-sm-12 nopadding">
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12"><img src="/images/hp-icons-1.png" /></div>
          <div class="">Mental Health</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12"><img src="/images/hp-icons-2.png" /></div>
          <div class="">Life Coaching</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12"><img src="/images/hp-icons-3.png" /></div>
          <div class="">Executive Coaching</div>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-7 col-sm-offset-1 sec-2-img">
      <img src="/images/2021/hp-sec-1.png" />
    </div>
  </div>
</section>

<div class="home-latestnews">
  <div class="container">
    <h2>Latest News</h2>

    <div>
      <div>
        <img src="/images/article-images/workplace-burnout.jpg" />
        <h3>Workplace Burnout</h3>
        <h5>19/06/2021</h5>
        <p>
          Workplace burnout is a special type of work-related stress - a state of physical or emotional exhaustion that also involves a sense of reduced accomplishment and loss of personal identity.
        </p>
        <a routerLink="/articles/workplace-burnout">Learn more</a>
      </div>

      <div>
        <img src="/images/article-images/startup-founders-and-mental-health.jpg" />
        <h3>Start-Up Founders & Mental Health</h3>
        <h5>10/07/2021</h5>
        <p>
          It is true that being a start-up founder is incredibly stressful. Mental wellbeing is an important factor for every founder.
        </p>
        <a routerLink="/articles/startup-founders-and-mental-health">Learn more</a>
      </div>

      <div>
        <img src="/images/article-images/what-is-procrastination.jpg" />
        <h3>What is Procrastination?</h3>
        <h5>22/07/2021</h5>
        <p>
          Procrastination is the act of delaying doing something until later even though it is in our best interest to do it now.
        </p>
        <a routerLink="/articles/what-is-procrastination">Learn more</a>
      </div>
    </div>
  </div>
</div>

<div class="home-pros">
  <div></div>
  <div>
    <div class="container">
      <h2>Meet some of our <br />MindBerry Professionals</h2>
      <p>
        MindBerry has over 50 qualified and experienced mental health & coaching professionals working on the platform and look forward to meeting with you
      </p>
      <div>
        <div>
          <img src="/images/2021/mb-hp-pros-6B718319.jpg" />
          <h4>Alexandra Taylor</h4>
          <p>
            I am a cognitive therapist, Using CBT, Hypnosis, NLP and Life Coaching to support you in achieving your full potential
          </p>
          <a href="https://mind-berry.com/profile/6B718319">View profile</a>
        </div>

        <div>
          <img src="/images/2021/mb-hp-pros-762ED261.jpg" />
          <h4>Payal Pandya</h4>
          <p>
            Counselling & Life Coaching with Payal – A pathway to healing recovery. Change and fulfilment
          </p>
          <a href="https://mind-berry.com/profile/762ED261">View profile</a>
        </div>

        <div>
          <img src="/images/2021/mb-hp-pros-E0732758.jpg" />
          <h4>Brad McCaw</h4>
          <p>
            Coach | Psychotherapist in training with an integrative approach of empathy and compassion
          </p>
          <a href="https://mind-berry.com/profile/E0732758">View profile</a>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</div>

<section class="sec-3">
  <div class="container">
    <div class="">
      <h1>How it works</h1>
    </div>

    <div class="col-sm-6">
      <div class="">
        <img src="/images/sec-3-1.png" />
      </div>

      <div class="sec-inner">
        <h2>Search and find</h2>

        <p>
          Search for coaching or mental health professionals who are right for you based on your needs and preferences.
        </p>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="">
        <img src="/images/sec-3-2.png" />
      </div>

      <div class="sec-inner">
        <h2>Connect and communicate</h2>

        <p>
          Book and attend your sessions on your phone or computer using our communication tools.
        </p>
      </div>
    </div>

    <div class="clear"></div>
  </div>

  <br /><br />

  <div class="container">
    <div class="sec-4">
      <h1>Start working with a <br />MindBerry professional today</h1>

      <br />
      <button class="btn-main" type="button" routerLink="/myaccount">SIGN UP</button>
      <br />

    </div>
  </div>

  <div class="clear"></div>
</section>

<section class="sec-5">
  <div class="container">
    <div class="ad-sec ad-bg-1">
      <div class="col-md-5 col-sm-12">
        <h1>MindBerry for Work</h1>
        <p>
          Find out how MindBerry can boost wellbeing and productivity for your organisation
        </p>

        <button class="btn-simple" type="button" routerLink="/mindberry-for-work">Learn more</button>
      </div>

      <div class="clear"></div>
    </div>

    <div class="ad-sec ad-bg-3">
      <div class="col-md-5 col-sm-12">
        <h1>MindBerry for Business</h1>
        <p>
          Take your mental health or coaching business online with our business management solution
        </p>

        <button class="btn-simple" type="button" routerLink="/mindberry-for-business">Learn more</button>
      </div>

      <div class="clear"></div>
    </div>

    <div class="ad-sec  ad-bg-2">
      <div class="col-md-5 col-sm-12">
        <h1>MindBerry for Professionals</h1>
        <p>
          Grow and enhance your professional practice by becoming a MindBerry professional
        </p>

        <button class="btn-simple" type="button" routerLink="/professionals">Learn more</button>
      </div>

      <div class="clear"></div>
    </div>
  </div>
</section>

<div class="clear"></div>

<affiliate></affiliate>
