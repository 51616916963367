import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ContactVM } from "../../models/models";
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'holland-barret',
  templateUrl: './hollandandbarret.component.html',
})

export class HollandAndBarretComponent implements OnInit {

  model: ContactVM = new ContactVM;
  myForm: FormGroup;
  hbFormStep: number = 1;
  isProcessed: boolean = false;

  constructor(private router: Router, private titleService: Title, private meta: Meta, private fb: FormBuilder, private contactService: ContactService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle('Holland&Barrett | MindBerry - Because Your Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Sign up' });
    this.meta.addTag({ name: 'keywords', content: 'Sign up' });

    window.scrollTo(0, 0);

    this.myForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required])
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  hbFormStatus(status: number) {
    this.hbFormStep = status;
  }

  submitHBForm() {
    if (!this.myForm.valid) {
      this.markAsTouched(this.myForm);
    }

    this.model.subject = "Holland&Barrett Enquiry"
    this.model.message = "Interest from Holland&Barrett Customer"

    this.contactService.Contact(this.model)
      .subscribe(returnValue => {
        if (returnValue.ok) {
          this.isProcessed = true;
        }
      });
  }

  markAsTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }
}
