import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'nhs',
  templateUrl: './nhs.component.html',
})
export class NHSComponent {
  constructor(private titleService: Title, private meta: Meta) {

  }

  ngOnInit() {
    this.titleService.setTitle('FREE support for NHS Staff - MindBerry Because Your Mind Matters');
    this.meta.addTag({ name: 'description', content: 'MindBerry is supporting the NHS through these difficult times. We are offering FREE support to NHS workers.' });
    this.meta.addTag({ name: 'keywords', content: 'NHS Workers Wellbeing, FREE Mental Health, NHS Online therapy UK, NHS Online counselling UK' });

    window.scrollTo(0, 0);
  }
}
