<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>MindBerry <br />Terms of Use</h1>
        <p>The small print</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/banner-signup.png" />
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="legal-content">
      <h1>MIND-BERRY GROUP LIMITED</h1>
      <h2>END-USER LICENCE AGREEMENT</h2>

      <p>
        Please read the following important terms of use (“Terms”) carefully. These Terms govern your access to and use of the Platform (defined in Section 2 below), and constitute a binding legal agreement among you, as a user of the Platform, and MindBerry Group Limited, Mind-Berry.com, and any other entities controlling, controlled by or under common control with the foregoing (collectively, “MindBerry”, “we” or “us”). “You” or “your” or similar terms refer to you as a user of our Platform (defined below).
      </p>
      <p>
        MindBerry is a telehealth platform in that we offer to connect people with our network of affiliated well-being and mental health professionals (“Service Providers”) to obtain online guidance, counselling and therapy services. Service Providers may include, but are not limited to: therapists, counsellors, coaches, psychologists, social workers, and marriage and family therapists. Any counselling or therapy services delivered through the Platform is not provided by MindBerry but by the Service Provider.
      </p>

      <h2>
        USE OF MINDBERRY IS NOT MEANT FOR EMERGENCIES. IF YOU THINK YOU HAVE A MEDICAL OR MENTAL HEALTH EMERGENCY, IF YOU ARE CONSIDERING OR CONTEMPLATING SUICIDE, OR FEEL THAT YOU ARE A DANGER TO YOURSELF OR TO OTHERS, YOU MUST DISCONTINUE USE OF THE SERVICES IMMEDIATELY AND SEEK MORE APPROPRIATE HELP
      </h2>

      <!-- 1 -->
      <h2>1. Applicability of these Terms.</h2>

      <p>
        Your access to and use of the Platform is conditioned on your compliance with these Terms. By becoming a registered user and/or accessing and/or using the Platform you agree to be bound by these Terms and all applicable laws and regulations governing the Platform. If you do not agree with these Terms, you are not authorised to access or use the Platform for any purpose.
      </p>

      <!-- 2 -->
      <h2>2. Our Platform.</h2>

      <p>
        The MindBerry platform includes, without limitation, the following services (collectively, the “Platform”):
      </p>

      <ol type="a">
        <li>the facilitation of electronic communications with Service Providers,</li>
        <li>the provision of appointment scheduling and reminders and other services related to online counselling and therapy for both our registered users and Service Providers,</li>
        <li>arrangements for payment by your Account manager for the services provided by Service Providers, and</li>
        <li>the provision of other information about MindBerry and our products and services through our website, <a class="btn-simple" href="https://Mind-Berry.com">https://Mind-Berry.com</a> (the “Platform”).</li>
      </ol>

      <p>
        The Platform does not include the provision of medical care, mental health services, or other professional services by MindBerry. Service Providers are not the employees or agents of MindBerry. Each Service Provider is responsible for obtaining your informed consent to any diagnosis or treatment, including, without limitation, your consent to use telehealth in the course of any consultation conducted through the Platform, to the extent such consent is required by the applicable laws of the relevant jurisdiction.
      </p>

      <!-- 3 -->
      <h2>3. Delivery of medical advice.</h2>

      <p>
        While the Platform may provide access to certain general medical information, and also may provide messaging functionality to contact your Service Provider(s), the Platform cannot and is not intended to provide medical advice. We advise you to always seek the advice of a physician or other qualified healthcare provider with any questions regarding your personal health or medical conditions. Never disregard, avoid or delay in obtaining medical advice from your doctor or other qualified healthcare providers because of something you have read on the Platform. If you have or suspect that you have a medical problem or condition, please contact a qualified healthcare professional immediately.
      </p>
      <p>
        To the extent advice is provided to you by a Service Provider through the Platform, such advice is based on your personal health information as provided by you and the local standards of care for your presenting symptoms, based on your use of the Platform. Responses are not provided by MindBerry, but are provided by your Service Provider.
      </p>
      <p>
        The content on the Platform (other than a direct response from a qualified Service Provider) is not and should not be considered medical advice or a substitute for individual medical advice, diagnosis, or treatment. You should always talk to your medical professionals for diagnosis and treatment, including information regarding which drugs or treatment may be appropriate for you. None of the information on the Site represents or warrants that any particular drug or treatment is safe, appropriate, or effective for you.
      </p>

      <!-- 4 -->
      <h2>4. Registered user accounts.</h2>

      <p>
        In order to access the members-only portions of the Platform, you must provide us with a current, valid email address so that we may contact you. By becoming a registered user, you agree to keep your email address updated.
      </p>
      <p>
        When you register, you will be asked to choose a password. You are responsible for safeguarding and maintaining the confidentiality of your password and you agree not to disclose your password to any third party.
      </p>
      <p>
        You must notify us immediately if you know or suspect that any unauthorised person is using your password or your account (for example, your password has been lost or stolen, someone has attempted to use the Platform through your account without your consent, or your account has been accessed without your permission). We strongly recommend that you do not use the Platform on public computers. We also recommend that you do not store your password through your web browser or other software.
      </p>
      <p>
        You agree that the information you provide to us at all times, including during registration and in any information you upload to your MindBerry online profile maintained by or through the Platform (whether required by us or not) will be true, accurate, current, and complete. This information includes, but is not limited to, name, address, age, phone number, email addresses, and payment information. Changes can be made in your user profile and you are solely responsible for the accuracy and completeness of your information.
      </p>
      <p>
        There is no guarantee that you will be accepted as a registered user, or as a client by one of our Service Providers. Even if you are accepted as a client by a Service Provider, your Service Provider may determine that online counselling services are not appropriate for some or all of your treatment needs, and accordingly may elect not to provide online counselling services to you through the Platform in your Service Provider’s sole discretion.
      </p>
      <p>
        By becoming a registered user, you expressly consent to the use of: (a) electronic means to complete these Terms and to provide you with any notices given pursuant to these Terms; and (b) electronic records to store information related to these Terms or your use of the Platform. MindBerry cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
      </p>

      <!-- 5 -->
      <h2>5. Service Provider profiles.</h2>

      <p>
        As part of MindBerry’s platform, MindBerry may provide profile pages for Service Providers to enable them to post relevant information about their education, training, experience, and areas of specialisation. Service Providers are solely and exclusively responsible for the content of their respective profiles, and MindBerry expressly disclaims any and all liability for the content of the Service Provider profiles, including, without limitation, the accuracy or reliability of any information contained therein.
      </p>

      <!-- 6 -->
      <h2>6. Your MindBerry profile.</h2>

      <p>
        Your MindBerry profile will be established and maintained for you as a registered user of the Platform to enter, store, and access your information online, and for your Service Providers to communicate with you about your care. All of the information contained in your MindBerry profile will be maintained in accordance with our Terms and our Privacy Policy. You agree to provide accurate and complete information for your MindBerry profile, to periodically review such information, and to update information that you provide as needed. Please refer to our <a class="btn-simple" routerLink="/privacy-policy">Privacy Policy</a> for more information.
      </p>
      <p>
        It is the obligation of each of your Service Providers and MindBerry to use and disclose any information included in your MindBerry profile in accordance with applicable laws, including, without limitation, obtaining any consents or authorisations that may be required for your information to be shared with third parties. By registering for the services and designating your Service Provider(s), you agree to disclose the contents of your complete MindBerry profile to your designated Service Provider(s).
      </p>
      <p>
        Messages transmitted through MindBerry's secure messaging platform are saved and become part of your MindBerry profile until you delete them.
      </p>

      <!-- 7 -->
      <h2>7. Responsibility for your care; verifying credentials of Service Providers.</h2>

      <p>
        Your medical care and your mental health care are solely the responsibility of you and your Service Provider(s). Under the Platform, mental health advice and services are provided exclusively by Service Providers.
      </p>
      <p>
        <strong>
          All Service Providers available through the Platform represent that they have degrees, licences and/or certifications, as applicable, in the areas of psychology, psychotherapy, psychiatry, marriage and family therapy, social work, or counselling. MindBerry performs checks to confirm the credentials of all Service Providers and to validate that they are in good standing with their respective professional body. However, MindBerry is not responsible for credentialing Service Providers, makes no representation regarding the accuracy of Service Providers’ credentials, and expressly disclaims any liability for fraudulent credentials or claims by Service Providers. In addition, changes in your Service Provider’s professional status could occur between the time we perform an initial credential check and the time you select your Service Provider. We recommend that you separately confirm that your Service Provider is in good standing with his or her respective professional body/bodies.
        </strong>
      </p>

      <!-- 8 -->
      <h2>8. Privacy.</h2>

      <p>
        When you use the Platform, MindBerry has access to, and in many cases will monitor, your usage of the Platform as you send and receive Content (as defined in Section 13 below). By using the Platform, you agree that MindBerry may collect, use, and disclose information you provide during your use of the Platform, only in accordance with the terms of our Privacy Policy.
      </p>
      <p>
        As part of providing you the Platform, we may need to provide you with certain communications, such as appointment reminders, service announcements and administrative messages. These communications are considered part of the Platform and your Account, which you may not be able to opt out from receiving them.
      </p>
      <p>
        You should consider that standard email is not a secure means of communication. There is some risk that any information contained in email may be disclosed to, or intercepted, printed, or stored by, unauthorised third parties. MindBerry cannot ensure the security or confidentiality of messages sent by email.
      </p>
      <p>
        MindBerry also may contact you to cancel, schedule or reschedule appointments via messages through the Platform or by phone. By providing your contact information and agreeing to these Terms of Use, you give your consent to MindBerry to leave voice messages or speak with third parties at the phone numbers provided by you. Such contact by MindBerry will not divulge your personal health information and will be limited to confirmation or rescheduling of appointments with Service Providers. If you wish to opt out of such communications, please contact customer support at admin@Mind-Berry.com to request “Confidential Communications” and all reasonable requests shall be accommodated.
      </p>

      <!-- 9 -->
      <h2>9. User-supplied material.</h2>

      <p>
        If you supply any comments, information, or material via the Platform, you represent and warrant to us that you have the legal right to supply such material and that it will not violate any law or the rights of any person or entity. Except for any individually identifiable health information you submit to us, all information or material you supply to us through the Platform shall be deemed and shall remain our property, and you hereby assign to MindBerry all right, title, and interest in and to any such information or material, without any restriction or obligation to you.
      </p>

      <!-- 10 -->
      <h2>10. Restrictions on conduct.</h2>

      <p>
        The Platform may be used and accessed for lawful purposes only. You agree to abide by all applicable local, national and foreign laws, treaties and regulations in connection with your use of the Platform.
      </p>
      <p>
        In addition, without limitation, you agree that you will not do any of the following while using or accessing the Platform:
      </p>

      <ol type="a">
        <li>upload, post, email or otherwise transmit any Content to which you do not have the lawful right to copy, transmit and display (including any Content that would violate any confidentiality or fiduciary obligations that you might have with respect to the Content);</li>
        <li>upload, post, email or otherwise transmit any Content that infringes the intellectual property rights or violates the privacy rights of any third party (including without limitation copyright, trademark, patent, trade secret, or other intellectual property right, or moral right or right of publicity);</li>
        <li>use the Platform to collect or store personal data about other users without their express permission;</li>
        <li>knowingly include or use any false or inaccurate information in any profile;</li>
        <li>upload, post, email or otherwise transmit any unsolicited or unauthorised advertising, promotional materials, junk mail, spam, harassment, abuse, sexually dubious content, requests for research participation, chain letters, “pyramid schemes” or any other form of solicitation, as well as viruses or other computer code that may interrupt, destroy, limit the functionality of the Platform, or interfere with the access of any other user to the Platform;</li>
        <li>circumvent, disable, or otherwise interfere with security-related features of the Platform or features that prevent or restrict use or copying of any Content;</li>
        <li>use any meta tags or other hidden text or metadata utilising a MindBerry name, trademark, URL or product name;</li>
        <li>attempt to probe, scan or test the vulnerability of any MindBerry system or network or breach or impair or circumvent any security or authentication measures protecting the Platform;</li>
        <li>attempt to decipher, decompile, disassemble, reverse engineer, or otherwise attempt to discover or determine the source code of any software or any proprietary algorithm used to provide the Platform;</li>
        <li>use the Platform in any way that competes with MindBerry, including, without limitation, misrepresenting one’s identity or posing as a current or prospective client in order to solicit or recruit Service Providers, directly or indirectly; or</li>
        <li>encourage or instruct any other person or entity to do any of the foregoing.</li>
      </ol>

      <!-- 11 -->
      <h2>11. Termination; cancellation.</h2>

      <p>
        MindBerry is continually evolving and innovating. We may change our Platform, the Content we offer, and the products or services you may access at any time. We may discontinue offering our Platform or Site and we may suspend or terminate your right to use our Platform or Site at any time, in the event that you breach these Terms, for any reason, or for no reason at all, in our sole discretion, and without prior notice to you. After such termination, MindBerry will have no further obligation to provide the Platform.
      </p>
      <p>
        Upon termination of your right to use our Platform or Site or our termination of the Platform or Site, all licences and other rights granted to you by these Terms will immediately terminate.
      </p>
      <p>
        Any suspension, termination, or cancellation will not affect your obligations to MindBerry under these Terms which by their nature are intended to survive such suspension, termination, or cancellation. For example, but not by way of limitation, upon any such suspension, termination, or cancellation the provisions of Section 12 (Ownership of Intellectual Property Rights), Section 14 (Third Party Websites; Advertisements), Section 15 (Disclaimer of Warranties), Section 16 (Indemnification), Section 17 (Limitation of Liability), Section 19 (General Terms), Section 20 (Arbitration) and Section 21 (Governing Law and Forum for Disputes) shall survive and remain in full force and effect, but the provisions of Section 15 (Your Licence to Use the Platform) shall be suspended, terminated or cancelled, as the case may be.
      </p>

      <!-- 12 -->
      <h2>12. Ownership of intellectual property rights.</h2>

      <p>
        The Platform, and all information and/or content that you see, hear, or otherwise experience on the Platform (collectively, “Content”) are protected by U.K. and international copyright, trademark, and other laws. We own or have the licence to use all of the intellectual property rights relating to MindBerry, the Platform, and the Content, including, without limitation, all intellectual property rights protected as patent pending or patented inventions, trade secrets, copyrights, trademarks, service marks, trade dress, or proprietary or confidential information, and whether or not they happened to be registered. You will not acquire any intellectual property rights in MindBerry by your use of the Platform.
      </p>

      <!-- 13 -->
      <h2>13. Your licence to use the Platform.</h2>

      <p>
        When you use our Platform you may access intellectual property rights that we or our licensors own or license. Subject to your compliance with the terms and conditions of these Terms, MindBerry grants you a limited, non-exclusive, non-transferable and revocable licence, without the right to sublicense, to access and use the Platform and to download and print any Content provided by MindBerry solely for your personal and non-commercial purposes. You may not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Platform or Content, except as expressly permitted in these Terms, without MindBerry’s express prior written consent. No licences or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by MindBerry or its licensors, except for the licences and rights expressly granted in these Terms.
      </p>

      <!-- 14 -->
      <h2>14. Third-party websites; advertisements.</h2>

      <p>
        We may make available, on our Platform, links to third party websites or resources from third parties of the Platform.
      </p>
      <p>
        MindBerry is not responsible or liable for the availability or accuracy of such third party websites or resources, nor does MindBerry endorse, sponsor, or recommend such websites or resources, or the content, products, or services on or available from such websites or resources. When we make available such third party links or resources on the Platform, you must look solely to the third party with respect to the content, products, or services they provide. We do not endorse and are not responsible for any of the content, products, or services provided by others.
      </p>
      <p>
        Your use of the websites or resources of third parties is at your own risk. MindBerry and its affiliates will not be liable for any of your losses arising out of or relating to the websites or resources of third parties.
      </p>

      <!-- 15 -->
      <h2>15. Disclaimer of warranties.</h2>

      <p>
        Your use of the Platform and Content is at your sole discretion and risk. The Platform and Content, and all materials, information, products and services included therein, are provided on an “AS IS” and “AS AVAILABLE” basis without warranties of any kind.
        MindBerry and its licensors and affiliates expressly disclaim all warranties of any kind, express, implied, or statutory, relating to the services and content, including without limitation the warranties of title, merchantability, fitness for a particular purpose, non-infringement of proprietary rights, course of dealing, or course of performance. MindBerry and its licensors and affiliates make no warranty that the content you access on our website or using our service satisfies the laws and regulations requiring the disclosure of information for prescription drugs.
      </p>
      <p>
        In addition, MindBerry and its licensors and affiliates disclaim any warranties regarding security, accuracy, reliability, timeliness and performance of the services or that the services will be error free or that any errors will be corrected. No advice or information provided to you by MindBerry will create any warranty that is not expressly stated in these Terms of Use.
      </p>
      <p>
        We make no representations concerning, and do not guarantee, the accuracy of the services, including, but not limited to, any information provided through the services or their applicability to your individual circumstances. Our services and site content are developed for use in the United Kingdom and MindBerry and its licensors and affiliates make no representation or warranty concerning the Platform or Platform content when they are used in any other country.
      </p>
      <p>
        Some jurisdictions do not permit us to exclude warranties in these ways, so it is possible that these exclusions will not apply to our agreement with you. In such event, the exclusions shall apply to the fullest extent permitted under applicable law.
      </p>

      <!-- 16 -->
      <h2>16. Indemnification.</h2>

      <p>
        You will indemnify, defend, and hold harmless MindBerry, MindBerry’s licensors and affiliates and our respective directors, officers, employees, contractors, agents and representatives, from and against any and all claims, causes of action, demands, liabilities, losses, costs or expenses (including, but not limited to, reasonable attorneys’ fees and expenses) arising out of or relating to any of the following matters:
      </p>

      <ol type="a">
        <li>your access to or use of the Platform or the Content;</li>
        <li>your violation of any of the provisions of these Terms of Use;</li>
        <li>any activity related to your Account by you or any other person accessing the Site or Platform through your Account, including, without limitation, negligent or wrongful conduct; or</li>
        <li>your violation of any third party right, including ,without limitation, any intellectual property right, publicity, confidentiality, property or privacy right.</li>
      </ol>

      <p>
        MindBerry reserves the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defences.
      </p>

      <!-- 17 -->
      <h2>17. Limitation of liability.</h2>

      <p>
        In no event will MindBerry, or MindBerry’s licensors and affiliates and our respective directors, officers, employees, contractors, agents and representatives, be liable to you for any damages whatsoever, including without limitation, indirect, incidental, special, punitive or consequential damages, or lost profits, arising out of or in connection with your use of the services, the Site, or the Content, whether the damages are foreseeable and whether or not MindBerry has been advised of the possibility of such damages in advance. If you are dissatisfied with the services, the Site, or the content or the Terms, your sole and exclusive remedy is to discontinue using the Platform.
      </p>
      <p>
        Nothing herein shall limit the potential professional liability of our Service Providers or other licensed healthcare professionals arising from or related to medical or mental health advice, diagnosis, or treatment they provide to you, except as provided under applicable country and state laws. In many jurisdictions, Service Providers are required to report confidential information if they have reason to believe that a client is likely to harm others or himself/herself. In no event shall MindBerry be liable for the disclosure of your confidential information by a Service Provider from whom you receive mental health services. MindBerry is not liable to any person or user for any harm caused by the negligence or misconduct of a Service Provider providing mental health services. In no event will the cumulative liability of MindBerry or MindBerry’s licensors or affiliates to you, whether in contract, tort, or otherwise, exceed £500.
      </p>
      <p>
        Except as otherwise required by applicable law, any claim or cause of action arising out of or relating to your use of the Platform or the Content or our relationship with you, regardless of theory, must be brought within one (1) year after the occurrence of the event giving rise to the claim or cause of action or be forever barred.
      </p>
      <p>
        Some jurisdictions do not permit us to limit liability in these ways, so it is possible that these limitations will not apply to our agreement with you. In such event, the limitations shall apply to the fullest extent permitted under applicable law.
      </p>

      <!-- 18 -->
      <h2>18. Errors and inaccuracies.</h2>

      <p>
        The information on the Platform may contain typographical errors or other errors or inaccuracies, and may not be complete or current. MindBerry reserves the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice to you. MindBerry will not, however, guarantee that any such errors, inaccuracies, or omissions will be corrected.
      </p>

      <!-- 19 -->
      <h2>19. General terms.</h2>

      <p>
        These Terms of Use constitute the entire agreement between you and us relating to our Platform, and the Content, replacing any prior or contemporaneous agreements, whether written or oral, unless you have signed a separate written agreement with us relating to our Platform, or the Content. If there is any conflict between the Terms and a separate signed written agreement between you and us relating to our Platform or the Content, the signed written agreement will control. Only the executive officers of MindBerry have the authority to sign a separate signed written agreement between you and us.
      </p>
      <p>
        There are no other third party beneficiaries to this agreement.
      </p>
      <p>
        The failure by you or us to enforce any provision of the Terms will not constitute a waiver. If any court of law, having the jurisdiction to decide the matter, rules that any provision of the Terms is invalid or unenforceable, then the invalid or unenforceable provision shall be removed from the Terms or reformed by the court and given effect so as to best accomplish the essential purpose of the invalid or unenforceable provision, and all of the other provisions of the Terms shall continue to be valid and enforceable. Nothing contained in these Terms of Use shall limit the ability of a party to seek an injunction or other equitable relief without posting any bond. The titles of the Sections of the Terms are for convenience only and shall have no legal or contractual effect.
      </p>

      <!-- 20 -->
      <h2>20. Arbitration.</h2>

      <p>
        For any claim (excluding claims for injunctive or other equitable relief) where the total amount of the award sought is less than £10,000.00 GBP the party requesting relief may elect to resolve the dispute in a cost-effective manner through binding non-appearance-based arbitration.
      </p>

      <p>
        The parties must comply with the following rules:
      </p>

      <ol type="a">
        <li>the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, the specific manner shall be chosen by the party initiating the arbitration;</li>
        <li>the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and</li>
        <li>any judgement on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</li>
      </ol>

      <p>
        Any dispute, controversy, or disagreement arising out of or relating to these Terms, the breach thereof, or the subject matter thereof, where the total amount of the award sought is £10,000.00 GBP or greater, shall be settled exclusively by binding arbitration. The arbitration shall be held in the United Kingdom, unless the parties mutually agree to have such proceeding in some other locale. To the extent of the subject matter of the arbitration, the arbitration shall be binding not only on all parties to these Terms, but on any other entity controlled by, in control of or under common control with the party to the extent that such affiliate joins in the arbitration, and judgement on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.
      </p>

      <!-- 21 -->
      <h2>21. Governing law and forum for disputes.</h2>

      <p>
        These Terms of Use and our relationship with you shall be governed by the laws of England and Wales, excluding its choice of laws rules. You and MindBerry each irrevocably agrees that the exclusive venue for any action or proceeding arising out of or relating to these Terms of Use or our relationship with you, regardless of theory, shall be United Kingdom Company’s court or Crown Court. You and MindBerry each irrevocably consents to the personal jurisdiction of these courts and waives any and all objections to the exercise of jurisdiction by these courts and to this venue. Notwithstanding the foregoing, however, you and MindBerry agree that MindBerry may commence and maintain an action or proceeding seeking injunctive or other equitable relief in any court of competent jurisdiction.
      </p>

      <!-- 22 -->
      <h2>22. Changes to these Terms.</h2>

      <p>
        We reserve the right to change our Terms at any time. Any changes that we make will become a part of our agreement with you when they are posted to our Platform. Your continued use of our Platform will constitute your agreement to the changes we have made. The last date these Terms were revised is set forth at the end of this document.
      </p>

      <!-- 23 -->
      <h2>23. Contacting us.</h2>

      <p>
        We encourage you to contact us at admin@Mind-Berry.com if you have any questions concerning our Terms. Please note that email communications will not necessarily be secure; accordingly, you should not include credit card information or other sensitive information in your email correspondence with us.
      </p>
    </div>
  </div>
</section>
