import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable ,  Subject } from 'rxjs';
import { ContactVM, B2BContactVM } from '../models/models';

@Injectable()
export class ContactService {

  private options = new HttpHeaders({'Accept': 'application/json'});
  // new Headers({ 'Content-Type': 'application/json' });
  // headers.append('Accept', 'application/json');
  // private options = new RequestOptions({ headers: this.headers });
  // const headers = new HttpHeaders();
  // headers.append('Authorization', 'Bearer ' + localStorage.getItem('sessionToken'));

  constructor(private http: HttpClient) {

  }

  public Contact(vm: ContactVM): Observable<any> {
    return this.http.post('/api/contact', vm, this.getHttpOptions(false));
  }

  public B2BContact(vm: B2BContactVM): Observable<any> {
    // TODO: recheck how endpoint works
    return this.http.post('/api/b2b-contact', vm, this.getHttpOptions(false));
  }

  protected getHttpOptions(authenticated: boolean, params?: URLSearchParams) {

    if (authenticated) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('sessionToken')
      });
      return { headers: headers };
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return (params != null) ? { headers: headers, search: params } : { headers: headers };
  }

}
