<div class="banner-main-alt article-banner">
  <div class="comp-ban-alt">
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 col-lg-offset-2 col-md-offset-1 col-sm-offset-1 nopadding">
        <div class="banner-content">
          <h1>Book a demo</h1>
          <br />
          <p>Leave your details below and a member of our team will be in touch.</p>
          <br />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-5 b-form-ban-img">
        <img src="/images/bform-ban-img.png" />
      </div>
    </div>
  </div>
</div>

<!-- Contact Form -->
<section *ngIf="isProcessed == false">
  <div class="container">
    <div class="col-sm-10 col-sm-offset-1">
      <div class="bform-wrap">
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()">

          <!-- Name -->
          <div class="col-sm-6">
            <div class="bform-sec">
              <div class="bf-title">Name</div>
              <input type="text" [formControl]="myForm.controls['name']" [(ngModel)]="model.name" />

              <div class="form-error-sec" *ngIf="!myForm.controls['name'].valid && myForm.controls['name'].touched">
                Required
              </div>
            </div>
          </div>

          <!-- Company Name -->
          <div class="col-sm-6">
            <div class="bform-sec">
              <div class="bf-title">Company Name</div>
              <input type="text" [formControl]="myForm.controls['companyName']" [(ngModel)]="model.companyName" />

              <div class="form-error-sec"
                   *ngIf="!myForm.controls['companyName'].valid && myForm.controls['companyName'].touched">
                Required
              </div>
            </div>
          </div>

          <div class="clear"></div>

          <!-- Email -->
          <div class="col-sm-6">
            <div class="bform-sec">
              <div class="bf-title">Email Address</div>
              <input type="text" [formControl]="myForm.controls['email']" [(ngModel)]="model.email" />

              <div class="form-error-sec" *ngIf="!myForm.controls['email'].valid && myForm.controls['email'].touched">
                Required
              </div>
            </div>
          </div>

          <!-- Contact Number -->
          <div class="col-sm-6">
            <div class="bform-sec">
              <div class="bf-title">Contact Number</div>
              <input type="text" [formControl]="myForm.controls['contactNumber']" [(ngModel)]="model.contactNumber" />

              <div class="form-error-sec"
                   *ngIf="!myForm.controls['contactNumber'].valid && myForm.controls['contactNumber'].touched">
                Required
              </div>
            </div>
          </div>

          <div class="clear"></div>

          <!-- Question 1 -->
          <div class="col-sm-6">
            <div class="bform-sec">
              <div class="bf-title">Approximately how many people does your business employ?</div>
              <select [formControl]="myForm.controls['noOfEmployees']" [(ngModel)]="model.noOfEmployees">
                <option value="1 - 15">1 - 15</option>
                <option value="16 - 50">16 - 50</option>
                <option value="51 - 150">51 - 150</option>
                <option value="151 - 250+">151 - 250+</option>
              </select>

              <div class="form-error-sec"
                   *ngIf="!myForm.controls['noOfEmployees'].valid && myForm.controls['noOfEmployees'].touched">
                Required
              </div>
            </div>
          </div>

          <!-- Question 2 -->
          <div class="col-sm-6">
            <div class="bform-sec">
              <div class="bf-title">Which MindBerry service are you enquiring about?</div>
              <select [formControl]="myForm.controls['serviceOfInterest']" [(ngModel)]="model.serviceOfInterest">
                <option value="MindBerry for Work">MindBerry for Work</option>
                <option value="MindBerry for Business">MindBerry for Business</option>
                <option value="Other">Other</option>
              </select>

              <div class="form-error-sec"
                   *ngIf="!myForm.controls['serviceOfInterest'].valid && myForm.controls['serviceOfInterest'].touched">
                Required
              </div>
            </div>
          </div>

          <!-- MB for WORK additional Questions -->
          <div *nIf="">

            <div class="col-sm-6">
              <div class="bform-sec">
                <div class="bf-title">Does your organisation have an EAP? (employee assistance program)</div>
                <select [formControl]="myForm.controls['eaporg']" [(ngModel)]="model.eaporg">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <div class="form-error-sec"
                     *ngIf="!myForm.controls['eaporg'].valid && myForm.controls['eaporg'].touched">
                  Required
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="bform-sec">
                <div class="bf-title">Which of these services aligns with your organisation's needs?</div>
                <select [formControl]="myForm.controls['orgneeds']" [(ngModel)]="model.orgneeds">
                  <option value="Counselling">Counselling</option>
                  <option value="Coaching">Coaching</option>
                  <option value="Both">Both</option>
                </select>

                <div class="form-error-sec"
                     *ngIf="!myForm.controls['orgneeds'].valid && myForm.controls['orgneeds'].touched">
                  Required
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="bform-sec">
                <div class="bf-title">What is your annual per employee budget for mental wellbeing? </div>
                <select [formControl]="myForm.controls['wellbeingbudget']" [(ngModel)]="model.wellbeingbudget">
                  <option value="£0 - £600">£0 - £600</option>
                  <option value="£600 - £1200">£600 - £1200</option>
                  <option value="£1200 - £2400">£1200 - £2400</option>
                  <option value="£2400 - £3600">£2400 - £3600</option>
                  <option value="£3600+">£3600+</option>
                </select>

                <div class="form-error-sec"
                     *ngIf="!myForm.controls['wellbeingbudget'].valid && myForm.controls['wellbeingbudget'].touched">
                  Required
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="bform-sec">
                <div class="bf-title">Within what time frame are you looking to implement a mental wellbeing solution?</div>
                <select [formControl]="myForm.controls['wellbeingtimeframe']" [(ngModel)]="model.wellbeingtimeframe">
                  <option value="1 month">1 month</option>
                  <option value="2 months">2 months</option>
                  <option value="3 months">3 months</option>
                  <option value="3+ months">3+ months</option>
                </select>

                <div class="form-error-sec"
                     *ngIf="!myForm.controls['wellbeingtimeframe'].valid && myForm.controls['wellbeingtimeframe'].touched">
                  Required
                </div>
              </div>
            </div>

          </div>

          <div class="clear"></div>

          <!-- Additional Info -->
          <div class="col-sm-12">
            <div class="bform-sec">
              <div class="bf-title">Additional Information</div>
              <textarea [formControl]="myForm.controls['additionalInfo']" [(ngModel)]="model.additionalInfo"></textarea>
            </div>
          </div>

          <!-- Where did you find us  -->
          <div class="col-sm-12">
            <div class="bform-sec">
              <div class="bf-title">Where did you hear about us?</div>
              <select [formControl]="myForm.controls['wheredidyoufindus']" [(ngModel)]="model.wheredidyoufindus">
                <option value="Search engine">Search engine</option>
                <option value="Event">Event</option>
                <option value="LinkedIn ">LinkedIn </option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="Publication or blog">Publication or blog</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div class="clear"></div>

          <div class="centered push-down-20">
            <div class="col-lg-4 col-md-6 col-sm-8 col-lg-offset-4 col-md-offset-3 col-sm-offset-2">
              <button class="btn-main btn-full" type="submit">SEND</button>
            </div>
          </div>
        </form>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="isProcessed && success">
  <!-- CONTACT FORM SUCCESS -->
  <div class="col-sm-12">
    <div class="panel-outer contact-panel">
      <div class="col-sm-4 col-xs-6 col-sm-offset-4 col-xs-offset-3">
        <img src="/images/icon-tick.png" />
      </div>

      <div class="clear"></div>

      <div class="panel-content">
        <h1>Your message has been <br />successfully sent </h1>

        <p>
          Thank you for your enquiry. A member of the team will be in touch as soon as possible.
        </p>
      </div>

      <button type="button" routerLink="/home" class="btn-main">Home</button>
    </div>
  </div>
  <div class="clear"></div>
</section>

<div class="clear"></div>

<section *ngIf="isProcessed && success == false">
  <!-- CONTACT FORM ERROR -->
  <div class="col-sm-12">
    <div class="panel-outer contact-panel">
      <div class="col-sm-4 col-xs-6 col-sm-offset-4 col-xs-offset-3">
        <img src="/images/icon-cross.png" />
      </div>

      <div class="clear"></div>

      <div class="panel-content">
        <h1>There was and ERROR <br /> while submitting the form</h1>

        <p>
          Please wait and try again later.
        </p>
      </div>

      <button type="button" routerLink="/contact" class="btn-error">Try Again</button>
    </div>
  </div>
  <div class="clear"></div>
</section>

<div class="clear"></div>
