<!-- Banner -->
<div class="banner-main-alt nhs-banner">
  <div class="container">
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/nhs-banner-img-2021.png" />
    </div>

    <div class="col-lg-5 col-md-6 col-sm-6 col-lg-offset-1 col-md-offset-1 col-sm-offset-1 nopadding">
      <div class="nhs-banner-content">
        <span>FREE</span><br />
        MENTAL HEALTH SUPPORT FOR
      </div>
      <div class="nhs-logo-banner-sec">
        <img src="/images/nhs-banner-logo.jpg" /> Staff
      </div>
    </div>
  </div>
</div>

<!--<br /><br />-->

<!-- Sec 1 -->
<!--<section>
  <div class="container">
    <div class="col-md-5 col-sm-5 col-md-offset-2 col-sm-offset-2 sec-2-img pull-right">
      <img src="/images/nhs-sec-1.jpg" />
    </div>

    <div class="mobile-clear"></div>

    <div class="col-md-5 col-sm-5">
      <div class="content">
        <h1>Here for you</h1>
        <p>
          To support <strong>NHS frontline workers</strong> who's own physical and mental wellbeing is at risk
          during this incredibly challenging time, MindBerry is offering <strong>FREE online</strong> mental health
          & coaching sessions for <strong>NHS frontline workers</strong>.
        </p>
        <p>
          Currently this support is available for NHS frontline hospital and care home workers only.
        </p>
      </div>
    </div>
  </div>
</section>-->

<!-- Sec 2 -->
<!--<section class="nhs-sec-3-bg">
  <div class="nhs-sec-3-bg-inner">
    <div class="nhs-sec-3-main-outer">
      <h1>Signing up is easy</h1>

      <div class="container">
        <div class="col-lg-4 col-md-4 col-sm-5 col-lg-offset-1 col-md-offset-1 col-sm-offset-1 pro-sec-3-img nopadding">
          <img src="/images/sec-3-1.png" />
        </div>

        <div class="col-lg-7 col-md-7 col-sm-6">
          <div class="col-sm-11 col-sm-offset-1">
            <div class="nhs-list-sec-wrap">-->
              <!-- 1 -->
              <!--<div class="col-lg-12 col-md-12 col-sm-12 nhs-list-sec nopadding">
                <div class="col-sm-1 col-xs-2 nopadding">
                  <div class="numb-sec">
                    1
                  </div>
                </div>

                <div class="col-sm-11 col-xs-10 push-down-5">
                  <strong>Register for support using your NHS ID</strong>
                </div>

                <div class="clear"></div>
              </div>-->
              <!-- 2 -->
              <!--<div class="col-lg-12 col-md-12 col-sm-12 nhs-list-sec nopadding">
                <div class="col-sm-1 col-xs-2 nopadding">
                  <div class="numb-sec">
                    2
                  </div>
                </div>

                <div class="col-sm-11 col-xs-10 push-down-5">
                  <strong>We'll send you a special sign up link for NHS workers</strong>
                </div>

                <div class="clear"></div>
              </div>-->
              <!-- 3 -->
              <!--<div class="col-lg-12 col-md-12 col-sm-12 nhs-list-sec nopadding">
                <div class="col-sm-1 col-xs-2 nopadding">
                  <div class="numb-sec">
                    3
                  </div>
                </div>

                <div class="col-sm-11 col-xs-10 push-down-5">
                  <strong>Create an account</strong>
                </div>

                <div class="clear"></div>
              </div>-->
              <!-- 4 -->
              <!--<div class="col-lg-12 col-md-12 col-sm-12 nhs-list-sec nopadding">
                <div class="col-sm-1 col-xs-2 nopadding">
                  <div class="numb-sec">
                    4
                  </div>
                </div>

                <div class="col-sm-11 col-xs-10 push-down-5">
                  <strong>Choose a professional</strong>
                </div>

                <div class="clear"></div>
              </div>-->
              <!-- 5 -->
              <!--<div class="col-lg-12 col-md-12 col-sm-12 nhs-list-sec nopadding">
                <div class="col-sm-1 col-xs-2 nopadding">
                  <div class="numb-sec">
                    5
                  </div>
                </div>

                <div class="col-sm-11 col-xs-10 push-down-5">
                  <strong>Book a session at a time of your choosing</strong>
                </div>

                <div class="clear"></div>
              </div>-->
              <!-- 6 -->
              <!--<div class="col-lg-12 col-md-12 col-sm-12 nhs-list-sec nopadding">
                <div class="col-sm-1 col-xs-2 nopadding">
                  <div class="numb-sec">
                    6
                  </div>
                </div>

                <div class="col-sm-11 col-xs-10 push-down-5">
                  <strong>Have your session online from a place of your choosing</strong>
                </div>

                <div class="clear"></div>
              </div>

              <div class="clear"></div>
            </div>

            <div class="mobile-centred-txt push-down-30">
              <a class="btn-main" href="https://mindberry8.typeform.com/to/ElYOKF" target="_blank">Register</a>
            </div>
          </div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>-->

<br /><br />

<!-- Sec 3 -->
<section>
  <div class="container">
    <div class="col-sm-6 col-sm-offset-2 sec-2-img pull-right">
      <img src="/images/nhs-sec-3.jpg" />
    </div>

    <div class="mobile-clear"></div>

    <div class="col-sm-4">
      <div class="content">
        <h1>Let’s get started</h1>
        <p>
          Please click the button below to register for your three <strong>FREE</strong> 25 minute sessions.
          You will need to provide your NHS ID card.
        </p>
        <p>
          Sessions will take place online via MindBerry's secure communication platform that gives users a choice of
          video, audio and instant messaging options.
        </p>
      </div>

      <div class="mobile-centred-txt">
        <a class="btn-main" href="https://client.mind-berry.com/signup/NHS" target="_blank">Register</a>
      </div>
    </div>
  </div>
</section>

<br /><br />
