import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'article',
  templateUrl: './article.component.html',
})
export class ArticleComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Blog | MindBerry - Because The Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Articles and updates from the MindBerry team' });
    this.meta.addTag({ name: 'keywords', content: 'Mental health blog ,Mental wellbeing blog, Therapy blog, Counselling blog, Coaching blog, Psychotherapy blog, Mental health, Mental wellbeing, Coaching, Psychotherap' });

    window.scrollTo(0, 0);
  }
}
