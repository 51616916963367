import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'error-page-not-found',
  templateUrl: './error-page-not-found.component.html',
})
export class ErrorPageNotFoundComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Error 404 - Page not found');
    this.meta.addTag({ name: 'description', content: 'Error 404 Page not found' });
    this.meta.addTag({ name: 'keywords', content: 'Error, 404, Page not found' });

    window.scrollTo(0, 0);
  }
}
