import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'for-business',
  templateUrl: './forbusiness.component.html',
})
export class ForBusinessComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Mental Health Services for Businesses | MindBerry UK');
    this.meta.addTag({ name: 'description', content: 'Take your business to the next level with MindBerry business management solution ideal for insurer, mental health & coaching businesses, & charities.' });
    this.meta.addTag({ name: 'keywords', content: 'Practice management, Counselling, Therapy, Therapist, Coaching, Booking system, Psychotherapy, Online counselling, Online therapy, Online therapist, Online coaching, Online psychotherapy' });

    window.scrollTo(0, 0);
  }
}
