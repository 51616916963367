import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CounsellorPublicProfileVM } from '../../models/CounsellorPublicProfileVM';
import { ActivatedRoute, Router } from '@angular/router';
import { CounsellorService } from '../../services/counsellor.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent {

  profileModel: CounsellorPublicProfileVM = new CounsellorPublicProfileVM();

  constructor(private titleService: Title, private meta: Meta, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle('Counsellor Profile | Therapy, counselling & coaching online');
    this.meta.addTag({ name: 'description', content: 'Easy, secure and private. The MindBerry platform lets you connect and work online with qualified therapists, counsellors and coaches.' });
    this.meta.addTag({ name: 'keywords', content: 'Online therapy UK, Online counselling UK, Coaching online, Business coaching, Mental health for business, Therapy UK, Counselling UK, Business coaching UK, Performance coaching UK, Life coaching UK' });

    if (this.route.snapshot.data['profile']) {
      this.profileModel = this.route.snapshot.data['profile'];

      if (this.profileModel == null) {
        this.router.navigateByUrl('/');
      }
    }

    window.scrollTo(0, 0);
  }
}
