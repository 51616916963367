import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Online Therapy - Mental Health Services in UK | MindBerry');
    this.meta.addTag({ name: 'description', content: 'Easy, secure, and private. The MindBerry platform lets you connect and work online with qualified therapists, counsellors, and coaches. Get in touch now!' });
    this.meta.addTag({ name: 'keywords', content: 'Online therapy UK, Online counselling UK, Coaching online, Business coaching, Mental health for business, Therapy UK, Counselling UK, Business coaching UK, Performance coaching UK, Life coaching UK' });

    window.scrollTo(0, 0);
  }
}
