<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>MindBerry <br />Privacy Policy</h1>
        <p>The small print</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/banner-signup.png" />
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="legal-content">
      <p>
        We understand that your privacy is important to you and that you care about how your personal data is used and
        shared online. We respect and value the privacy of everyone who visits this website, [www.mind-berry.com] (“Our
        Site”) and will only collect and use personal data in ways that are described here, and in a manner that is
        consistent with Our obligations and your rights under the law.
      </p>
      <p>
        Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of Our Privacy
        Policy is deemed to occur upon your first use of Our Site. If you do not accept and agree with this Privacy
        Policy, you must stop using Our Site immediately.
      </p>

      <!-- 1 -->
      <div class="push-down-15">
        <h2>1. Definitions and Interpretation </h2>

        <p>In this Policy the following terms shall have the following meanings:</p>

        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td valign="top" width="20%"><strong>“Account”</strong></td>
              <td>means an account required to access and/or use certain areas and features of Our Site;</td>
            </tr>

            <tr>
              <td>
                <div class="push-down-10"></div>
              </td>
              <td>
                <div class="push-down-10"></div>
              </td>
            </tr>

            <tr>
              <td valign="top" width="20%"><strong>“Cookie”</strong></td>
              <td>means a small text file placed on your computer or device by Our Site when you visit certain parts of
                Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are
                set out in section 13, below;</td>
            </tr>

            <tr>
              <td>
                <div class="push-down-10"></div>
              </td>
              <td>
                <div class="push-down-10"></div>
              </td>
            </tr>

            <tr>
              <td valign="top" width="20%"><strong>“Cookie Law”</strong></td>
              <td>means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003;
              </td>
            </tr>

            <tr>
              <td>
                <div class="push-down-10"></div>
              </td>
              <td>
                <div class="push-down-10"></div>
              </td>
            </tr>

            <tr>
              <td valign="top" width="20%"><strong>“personal data”</strong></td>
              <td>means any and all data that relates to an identifiable person who can be directly or indirectly
                identified from that data. In this case, it means personal data that you give to Us via Our Site. This
                definition shall, where applicable, incorporate the definitions provided in the EU Regulation 2016/679 –
                the General Data Protection Regulation (“GDPR”); and</td>
            </tr>

            <tr>
              <td>
                <div class="push-down-10"></div>
              </td>
              <td>
                <div class="push-down-10"></div>
              </td>
            </tr>

            <tr>
              <td valign="top" width="20%"><strong>“We/Us/Our”</strong></td>
              <td>means MindBerry Group Limited, a limited company registered in England under company number 101983334
                whose registered address is Vision House, 3a Dee Road, Richmond, TW9 2JN.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 2 -->
      <div class="push-down-15">
        <h2>2. Information About Us</h2>

        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td valign="top" width="10%"><strong>2.1</strong></td>
              <td>
                Our Site is owned by MindBerry Group Limited, a limited company registered in England under company
                number 101983334 whose registered address is Vision House, 3a Dee Road, Richmond, TW9 2JN
              </td>
            </tr>

            <tr>
              <td>
                <div class="push-down-10"></div>
              </td>
              <td>
                <div class="push-down-10"></div>
              </td>
            </tr>

            <tr>
              <td valign="top" width="10%"><strong>2.2</strong></td>
              <td>
                Our Data Protection Officer is Christian Mogele and can be contacted by email at
                christian.mogele@mind-berry.com.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 3 -->
      <div class="push-down-15">
        <h2>3. What Does This Policy Cover?</h2>

        <p>
          This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please
          note that We have no control over how your data is collected, stored, or used by other websites and We advise
          you to check the privacy policies of any such websites before providing any data to them.
        </p>
      </div>

      <!-- 4 -->
      <div class="push-down-15">
        <h2>4. What is Personal Data?</h2>

        <p>
          Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) as
          ‘any information relating to an identifiable person who can be directly or indirectly identified in particular
          by reference to an identifier’.
        </p>
        <p>
          Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data
          covers obvious information such as your name and contact details, but it also covers less obvious information
          such as identification numbers, electronic location data, and other online identifiers.
        </p>
      </div>
      <!-- 5 -->
      <h2>5. Your Rights</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>5.1</strong></td>
            <td>As a data subject, you have the following rights under the GDPR, which this Policy and Our use of
              personal data have been designed to uphold:</td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.1</strong></td>
                  <td>
                    The right to be informed about Our collection and use of personal data;
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.2</strong></td>
                  <td>
                    The right of access to the personal data We hold about you (see section 13);
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.3</strong></td>
                  <td>
                    The right to rectification if any personal data We hold about you is inaccurate or incomplete
                    (please contact Us using the details in section 16);
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.4</strong></td>
                  <td>
                    The right to be forgotten – i.e. the right to ask Us to delete any personal data We hold about you
                    (We only hold your personal data for a limited time, as explained in section 6 but if you would like
                    Us to delete it sooner, please contact Us using the details in section 16);
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.5</strong></td>
                  <td>
                    The right to restrict (i.e. prevent) the processing of your personal data;
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.6</strong></td>
                  <td>
                    The right to data portability (obtaining a copy of your personal data to re-use with another service
                    or organisation);
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.7</strong></td>
                  <td>
                    The right to object to Us using your personal data for particular purposes; and
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>5.1.8</strong></td>
                  <td>
                    Rights with respect to automated decision making and profiling.
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>5.2</strong></td>
            <td>
              If you have any cause for complaint about Our use of your personal data, please contact Us using the
              details provided in section 16 and We will do Our best to solve the problem for you. If We are unable to
              help, you also have the right to lodge a complaint with the UK’s supervisory authority, the Information
              Commissioner’s Office.
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>5.3</strong></td>
            <td>
              For further information about your rights, please contact the Information Commissioner’s Office or your
              local Citizens Advice Bureau.
            </td>
          </tr>

          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 6 -->
      <h2>6. What Data Do We Collect?</h2>

      <p>
        Depending upon your use of Our Site, We may collect some or all of the following personal and non-personal data
        (please also see section 14 on Our use of Cookies and similar technologies):
      </p>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="10%"><strong>6.1</strong></td>
            <td>name;</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.2</strong></td>
            <td>date of birth;</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.3</strong></td>
            <td>gender;</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.4</strong></td>
            <td>business/company name</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.5</strong></td>
            <td>job title;</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.6</strong></td>
            <td>profession;</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.7</strong></td>
            <td>contact information such as email addresses and telephone numbers;</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.8</strong></td>
            <td>demographic information such as post code, preferences and interests; and</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
          <tr>
            <td valign="top" width="10%"><strong>6.9</strong></td>
            <td>a list of URLs starting with a referring site, your activity on Our Site, and the site you exit to.</td>
          </tr>
        </tbody>
      </table>

      <!-- 7 -->
      <h2>7. How Do We Use Your Data?</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>7.1</strong></td>
            <td>All personal data is processed and stored securely, for no longer than is necessary in light of the
              reason(s) for which it was first collected. We will comply with Our obligations and safeguard your rights
              under the GDPR at all times. For more details on security see section 8, below.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>7.2</strong></td>
            <td>Our use of your personal data will always have a lawful basis, either because it is necessary for Our
              performance of a contract with you, because you have consented to Our use of your personal data (e.g. by
              subscribing to emails), or because it is in Our legitimate interests. Specifically, We may use your data
              for the following purposes:</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>7.2.1</strong></td>
                  <td>Providing and managing your Account;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>7.2.2</strong></td>
                  <td>Providing and managing your access to Our Site;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>7.2.3</strong></td>
                  <td>Supplying Our products to you (please note that We require your personal data in order to enter
                    into a contract with you); and</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>7.2.4</strong></td>
                  <td>Personalising and tailoring Our products for you.</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>7.3</strong></td>
            <td>You have the right to withdraw your consent to Us using your personal data at any time, and to request
              that We delete it.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>7.4</strong></td>
            <td>We do not keep your personal data for any longer than is necessary in light of the reason(s) for which
              it was first collected. Data will therefore be retained for the following periods (or its retention will
              be determined on the following bases):</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>7.4.1</strong></td>
                  <td>for six months after the contract entered into between you and Us when you purchase any of our
                    products or services through the Site is terminated, for whatever reason, to allow you time to
                    request the return or deletion of your data.</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 8 -->
      <h2>8. How and Where Do We Store Your Data?</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>8.1</strong></td>
            <td>We only keep your personal data for as long as We need to in order to use it as described above in
              section 7, and/or for as long as We have your permission to keep it.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>8.2</strong></td>
            <td>Some or all of your data may be stored outside of the European Economic Area (“the EEA”) (The EEA
              consists of all EU member states, plus Norway, Iceland, and Liechtenstein). You are deemed to accept and
              agree to this by using Our Site and submitting information to Us. If We do store data outside the EEA, We
              will take all reasonable steps to ensure that your data is treated as safely and securely as it would be
              within the UK and under the GDPR.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>8.3</strong></td>
            <td>Data security is very important to Us, and to protect your data We have taken suitable measures to
              safeguard and secure data collected through Our Site including:</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>8.3.1</strong></td>
                  <td>Registration with the Information Commissioner’s Office;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>8.3.2</strong></td>
                  <td>SSL Certificate;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>8.3.3</strong></td>
                  <td>Secure Database Access;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>8.3.4</strong></td>
                  <td>Encryption technology;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>8.3.5</strong></td>
                  <td>Record Level Auditing; and</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>8.3.6</strong></td>
                  <td>Data Backup.</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 9 -->
      <h2>9. Do We Share Your Data?</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>9.1</strong></td>
            <td>We may sometimes contract with third parties to supply products and services to you on Our behalf. These
              may include payment processing, search engine facilities, advertising, and marketing. In some cases, the
              third parties may require access to some or all of your data. Where any of your data is required for such
              a purpose, We will take all reasonable steps to ensure that your data will be handled safely, securely,
              and in accordance with your rights, Our obligations, and the obligations of the third party under the law.
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>9.2</strong></td>
            <td>Our Payment Processor is Stripe. You can read more about their approach to privacy and data protection
              in their privacy policy: <a class="btn-simple" href="https://stripe.com/gb/privacy"
                target="_blank">https://stripe.com/gb/privacy</a></td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>9.3</strong></td>
            <td>We may compile statistics about the use of Our Site including data on traffic, usage patterns, user
              numbers, sales, and other information. All such data will be anonymised and will not include any
              personally identifying data, or any anonymised data that can be combined with other data and used to
              identify you. We may from time to time share such data with third parties such as prospective investors,
              affiliates, partners, and advertisers. Data will only be shared and used within the bounds of the law.
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>9.4</strong></td>
            <td>We may sometimes use third party data processors that are located outside of the European Economic Area
              (“the EEA”) (The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein). Where We
              transfer any personal data outside the EEA, We will take all reasonable steps to ensure that your data is
              treated as safely and securely as it would be within the UK and under the GDPR including:</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>9.4.1</strong></td>
                  <td>Stripe (Payment Processor) see EU-US Privacy Shield Policy: <a class="btn-simple"
                      href="https://stripe.com/privacy-shield-policy"
                      target="_blank">https://stripe.com/privacy-shield-policy</a></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>9.5</strong></td>
            <td>In certain circumstances, We may be legally required to share certain data held by Us, which may include
              your personal data, for example, where We are involved in legal proceedings, where We are complying with
              legal requirements, a court order, or a governmental authority.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 10 -->
      <h2>10. What Happens If Our Business Changes Hands?</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>10.1</strong></td>
            <td>We may, from time to time, expand or reduce Our business and this may involve the sale and/or the
              transfer of control of all or part of Our business. Any personal data that you have provided will, where
              it is relevant to any part of Our business that is being transferred, be transferred along with that part
              and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to
              use that data only for the same purposes for which it was originally collected by Us.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>10.2</strong></td>
            <td>In the event that any of your data is to be transferred in such a manner, you will be contacted in
              advance and informed of the changes. When contacted you will not, however, be given the choice to have
              your data deleted or withheld from the new owner or controller.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 11 -->
      <h2>11. How Can You Control Your Data?</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>11.1</strong></td>
            <td>In addition to your rights under the GDPR, set out in section 5, when you submit personal data via Our
              Site, you may be given options to restrict Our use of your data. In particular, We aim to give you strong
              controls on Our use of your data for direct marketing purposes (including the ability to opt-out of
              receiving emails from Us which you may do by unsubscribing using the links provided in Our emails and at
              the point of providing your details and by managing your Account).</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>11.2</strong></td>
            <td>You may also wish to sign up to one or more of the preference services operating in the UK: The
              Telephone Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the
              Mailing Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing.
              Please note, however, that these services will not prevent you from receiving marketing communications
              that you have consented to receiving.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 12 -->
      <h2>12. Your Right to Withhold Information</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>12.1</strong></td>
            <td>You may access certain areas of Our Site without providing any data at all. However, to use all features
              and functions available on Our Site you may be required to submit or allow for the collection of certain
              data.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>12.2</strong></td>
            <td>You may restrict Our use of Cookies. For more information, see section 14.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 13 -->
      <h2>13. How Can You Access Your Data?</h2>

      <p>
        You have the right to ask for a copy of any of your personal data held by Us (where such data is held). Under
        the GDPR, no fee is payable and We will provide any and all information in response to your request free of
        charge. Please contact Us for more details at Christian.mogele@mind-berry.com, or using the contact details
        below in section 15.
      </p>

      <!-- 14 -->
      <h2>14. Our Use of Cookies</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>14.1</strong></td>
            <td>Our Site may place and access certain first party Cookies on your computer or device. First party
              Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve
              your experience of Our Site and to provide and improve Our products. </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>14.2</strong></td>
            <td>Our Site uses analytics services provided by Google. Website analytics refers to a set of tools used to
              collect and analyse anonymous usage information, enabling Us to better understand how Our Site is used.
              This, in turn, enables Us to improve Our Site and the products offered through it. You do not have to
              allow Us to use these Cookies, however whilst Our use of them does not pose any risk to your privacy or
              your safe use of Our Site, it does enable Us to continually improve Our Site, making it a better and more
              useful experience for you.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>14.3</strong></td>
            <td>The analytics service used by Our Site uses analytics Cookies to gather the required information.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>14.4</strong></td>
            <td>The analytics service used by Our Site uses the following analytics Cookies:</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong></strong></td>
            <td>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th valign="top">Name of Cookie</th>
                    <th valign="top">Purpose & Type</th>
                    <th valign="top">Provider</th>
                    <th valign="top">Strictly Necessary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Google Analytics</td>
                    <td>We use Google Analytics to collect information about how our Site is used. We can then use this
                      information to help us improve the site. The cookies collect information in an anonymous form,
                      including the number of visitors to the site, where visitors have come to the site from and the
                      pages they visited. </td>
                    <td>Google </td>
                    <td>Yes</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 15 -->
      <h2>15. Consent and Control</h2>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td valign="top" width="8%"><strong>15.1</strong></td>
            <td>Before Cookies are placed on your computer or device, you will be shown a pop-up requesting your consent
              to set those Cookies. By giving your consent to the placing of Cookies you are enabling Us to provide the
              best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies
              unless those Cookies are strictly necessary; however certain features of Our Site may not function fully
              or as intended. You will be given the opportunity to allow and/or deny different categories of Cookie that
              We use. </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>15.2</strong></td>
            <td>In addition to the controls that We provide, you can choose to enable or disable Cookies in your
              internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies
              or only third party Cookies. By default, most internet browsers accept Cookies but this can be changed.
              For further details, please consult the help menu in your internet browser or the documentation that came
              with your device.</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td valign="top" width="8%"><strong>15.3</strong></td>
            <td>The links below provide instructions on how to control Cookies in all mainstream browsers:</td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.1</strong></td>
                  <td>Google Chrome: <a class="btn-simple"
                      href="https://support.google.com/chrome/answer/95647?hl=en-GB"
                      target="_blank">https://support.google.com/chrome/answer/95647?hl=en-GB</a></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.2</strong></td>
                  <td>Microsoft Internet Explorer: <a class="btn-simple"
                      href="https://support.microsoft.com/en-us/kb/278835"
                      target="_blank">https://support.microsoft.com/en-us/kb/278835</a></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.3</strong></td>
                  <td>Microsoft Edge: <a class="btn-simple"
                      href="https://support.microsoft.com/en-gb/products/microsoft-edge"
                      target="_blank">https://support.microsoft.com/en-gb/products/microsoft-edge</a> (Please note that
                    there are no specific instructions at this time, but Microsoft support will be able to assist)</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.4</strong></td>
                  <td>Safari (macOS): <a class="btn-simple"
                      href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB"
                      target="_blank">https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB</a></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.5</strong></td>
                  <td>Safari (iOS): <a class="btn-simple" href="https://support.apple.com/en-gb/HT201265"
                      target="_blank">https://support.apple.com/en-gb/HT201265</a></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.6</strong></td>
                  <td>Mozilla Firefox: <a class="btn-simple"
                      href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences"
                      target="_blank">https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences</a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>
              <table>
                <tr>
                  <td valign="top" width="8%"><strong>15.3.7</strong></td>
                  <td>Android: <a class="btn-simple"
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en"
                      target="_blank">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en</a>
                    (Please refer to your device’s documentation for manufacturers’ own browsers).</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <div class="push-down-10"></div>
            </td>
            <td>
              <div class="push-down-10"></div>
            </td>
          </tr>

        </tbody>
      </table>

      <!-- 16 -->
      <h2>16. Contacting Us</h2>

      <p>
        If you have any questions about Our Site or this Privacy Policy, please contact Us by email at <a
          class="btn-simple" href="mailto:christian.mogele@mind-berry.com"
          target="_blank">christian.mogele@mind-berry.com</a>. Please ensure that your query is clear, particularly if
        it is a request for information about the data We hold about you.
      </p>

      <!-- 17 -->
      <h2>17. Changes to Our Privacy Policy</h2>

      <p>
        We may change this Privacy Policy from time to time (for example, if the law changes). Any changes will be
        immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your
        first use of Our Site following the alterations. We recommend that you check this page regularly to keep
        up-to-date.
      </p>
    </div>
  </div>
</section>
