import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'terms',
  templateUrl: './terms.component.html',
})
export class TermsComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Terms of Use | MindBerry - Because Your Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Read MindBerry terms and conditions.' });
    this.meta.addTag({ name: 'keywords', content: 'Terms and conditions, Mental health terms and conditions, Coaching terms and conditions' });

    window.scrollTo(0, 0);
  }
}
