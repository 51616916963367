<div class="banner-main-alt article-banner">
  <div class="comp-ban-alt">
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 nopadding">
        <div class="banner-content">
          <h1>Understanding the mental health risks of COVID-19 </h1>
          <br />
          <p>As the economy opens and the world returns to work, employers and other organisations face new HR
            challenges bought on by the coronavirus pandemic.</p>
          <br />
        </div>
        <div class="banner-btn">
          <!--<button class="btn-main" type="button" routerLink="/contact">TALK TO US ABOUT A TRIAL</button>-->
        </div>
      </div>
      <div class="col-lg-6 col-md-5 col-sm-5">
        <img src="/images/article-ban-img-covid.png" />
      </div>
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="article-sec">
      <h1>
        Returning to work:
      </h1>
      <p>
        COVID-19 has created an enormous impact on our lives and mental wellbeing. With more businesses reopening, some
        employees are anxious about going back to work. This anxiety may relate to personal safety or financial
        pressures faced
        as a result of the pandemic’s severe economic implications.
      </p>
    </div>

    <div class="article-sec">
      <h1>
        Remote working:
      </h1>
      <p>
        Working from home often results in the fission of our work and private lives. Newly remote workers can struggle
        to adapt to this change. They are more likely to report slow, inefficient work and communication habits which
        can hinder the speed of your business as well as its productivity. Some remote workers also experience feelings
        of loneliness and isolation when working from home.
      </p>
    </div>

    <div class="article-sec">
      <h1>
        Work-life balance:
      </h1>
      <p>
        COVID-19 has dramatically changed the dynamics of our lives and many of us need to adjust the mental model that
        separates our professional and private commitments. Working from home has been particularly stressful for
        families with
        children; as parents have had to take on the additional burden of home schooling. Support that helps employees
        address
        professional issues enables them to better support themselves, their families and colleagues through this
        difficult
        time.
      </p>
    </div>

    <div class="article-sec">
      <h1>
        Supporting shielders:
      </h1>
      <p>
        Some employees may be in the unique position of being amongst the at-risk population. The extra measures that
        these
        individuals are forced to take to ensure their safety, can take a considerable toll on their mental wellbeing.
        Feelings
        of anxiety and loneliness can be amplified for these individuals, due to the lengths they must go to, to
        self-isolate
        and social distance.
      </p>
    </div>

    <div class="article-sec">
      <h1>
        Bereavement:
      </h1>
      <p>
        One of the most challenging and saddest aspects of the COVID pandemic is the increased probability that your
        employees
        will be forced to confront the loss of loved ones.
      </p>

      <p>
        Bereavement is one of life’s most difficult processes and professional support can help dramatically as it is
        not uncommon for people to develop symptoms of depression and anxiety at this time. Bereaved staff need time,
        assistance and sincere understanding to help them cope and heal.
        Assurances that work can continue without them and clear communication around how they would stay connected are
        critical.
      </p>
    </div>

    <div class="article-sec">
      <h1>
        Bullying:
      </h1>
      <p>
        A rise cyberbullying is possible as a result of so many employees now working remotely from one another.
        Examples of
        cyberbullying may include frequent interruptions during virtual meetings, unkind emails as well as repeated and
        excessive emailing.
      </p>

      <p>
        If an employee feels excluded or bullied by colleagues, it does not matter whether that behaviour takes place
        electronically or in person - the risk to the business remains the same and will result in a breakdown in
        working relationships and lasting damage to productivity and company culture.
      </p>
    </div>

    <div class="article-sec">
      <h1>
        MindBerry:
      </h1>
      <p>
        The depth and breadth of the expertise offered by MindBerry’s network of mental health and coaching
        professionals gives
        any employer the tools they need to navigate the HR challenges of our new environment. Whether your employees
        need help
        with their mental wellbeing or need coaching to develop the skills they need to thrive, MindBerry has a
        professional
        that is right for the job.
      </p>

      <p>
        <button class="btn-main" type="button" routerLink="/business-form">BOOK A DEMO</button>
      </p>

    </div>



  </div>
</section>
