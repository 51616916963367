<footer>
  <div class="main-footer" style="padding:20px;">
    <div class="row">
      <div class="col-sm-3">
        <img src="/images/logo-main.png" style="max-width: 100px;"><ul class="list-unstyled"><li>MindBerry Group Limited,</li><li>Vision House, 3a Dee Road,</li><li>Richmond,</li><li>TW9 2JN</li></ul>
      </div>
      <div class="col-sm-9 row">
        <div class="col-sm-3">
          <h4>Navigate</h4>
          <ul class="list-unstyled">
            <li><a routerlink="/home" ng-reflect-router-link="/home" href="/home">Home</a></li>
            <li><a routerlink="/mindberry-for-work" ng-reflect-router-link="/mindberry-for-work" href="/mindberry-for-work">MB for work</a></li>
            <li><a routerlink="/mindberry-for-business" ng-reflect-router-link="/mindberry-for-business" href="/mindberry-for-business">MB for Business</a></li>
            <li><a routerlink="/professionals" ng-reflect-router-link="/professionals" href="/professionals">Professionals</a></li>
          </ul>
        </div>
        <div class="col-sm-3">
          <h4>Company</h4>
          <ul class="list-unstyled">
            <li><a routerlink="/about" ng-reflect-router-link="/about" href="/about">About Us</a></li>
            <li><a routerlink="/contact" ng-reflect-router-link="/contact" href="/contact">Contact Us</a></li>
            <li><a routerlink="/myaccount" ng-reflect-router-link="/myaccount" href="/myaccount">My Account</a></li>
          </ul>
        </div>
        <div class="col-sm-3">
          <h4>Resource</h4>
          <ul class="list-unstyled">
            <li role="presentation"><a routerlink="/terms-of-use" ng-reflect-router-link="/terms-of-use" href="/terms-of-use">Terms of use</a></li>
            <li><a routerlink="/privacy-policy" ng-reflect-router-link="/privacy-policy" href="/privacy-policy">Privacy Policy</a></li>
            <li><a routerlink="/ethical-standards" ng-reflect-router-link="/ethical-standards" href="/ethical-standards">Ethical Standards</a></li>
            <li><a routerlink="/emergency-contacts" ng-reflect-router-link="/emergency-contacts" href="/emergency-contacts">Emergency Contacts</a></li>
          </ul>
        </div>
        <div class="col-sm-3">
          <h4>Follow Us</h4>
          <div class="col-sm-12" style="margin:auto;padding:0; position: relative;width:120px;height:120px;">
            <a target="_blank" href="https://www.facebook.com/mindberry"><img src="/images/Facebook.png" style="width:50px;position:absolute;left:0px;"></a>
            <a target="_blank" href="https://www.instagram.com/mindberrywellbeing/"><img src="/images/Instagram.png" style="width:50px;position:absolute;left:70px;"></a>
            <a target="_blank" href="https://www.linkedin.com/company/mindberry-group"><img src="/images/LinkedIn.png" style="width:50px;position:absolute;top:70px;left:0px;"></a>
            <a target="_blank" href="https://twitter.com/mindberrygroup"><img src="/images/Twitter.png" style="width:50px;position:absolute;top:70px;left:70px;"></a>           
          </div>
        </div>
      </div>
    </div><div class="footer-sec-bottom"><p>© MindBerry 2023. All rights reserved.</p></div>
  </div>
</footer>
