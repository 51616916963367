import { Component } from "@angular/core";

@Component({
  selector: 'blogpost-workplace-burnout',
  templateUrl: './workplace-burnout.component.html',
})

export class BlogPostWorkplaceBurnoutComponent {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
