import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContactVM } from '../../models/models';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
})
export class ContactComponent implements OnInit {

  model: ContactVM = new ContactVM;
  myForm: FormGroup;
  isProcessed: boolean = false;

  constructor(private router: Router, private titleService: Title, private meta: Meta, private fb: FormBuilder, private contactService: ContactService) {

  }

  ngOnInit(): void {
    this.titleService.setTitle('Contact Us | MindBerry - Because Your Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Get in touch with the team at MindBerry.' });
    this.meta.addTag({ name: 'keywords', content: 'Contact us, Get in touch' });

    window.scrollTo(0, 0);

    this.myForm = this.fb.group({
      email: new FormControl('', [Validators.email, Validators.required]),
      name: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required)
    });

  }

  onSubmit(value: string) {
    if (!this.myForm.valid) {
      this.markAsTouched(this.myForm);
    }
    else {
      this.contactService.Contact(this.model)
        .subscribe(returnValue => {
          if (returnValue !== undefined && returnValue.ok) {
            this.isProcessed = true;
          }
        });
    }
  }

  markAsTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  resolved(event: any) {
    alert('ok');
  }

}
