<div class="banner-blog blog-header">
  <div class="container">
    <div class="blog-banner-content">
      <a class="btn btn-main" routerLink="/home">Back</a>
    </div>
  </div>
</div>

<div class="post-body-wrapper">
  <h1>What is Procrastination?</h1>
  <h2>Written by Alexandra Taylor <br /><span>Mediation Guide, CBT Therapist & MindBerry Professional</span></h2>
  <h3>22/07/2021</h3>

  <div class="post-image">
    <img src="/images/article-images/what-is-procrastination.jpg" />
  </div>

  <p>
    Procrastination is the act of delaying doing something until later even though it is in our best interest to do it now. 20% of adults in the general population are chronic procrastinators Problematic procrastination can be distinguished from more general procrastination by how bad the negative consequences are of us not following through on things.
  </p>

  <h4>Why Do We Procrastinate?</h4>
  <ul>
    <li>To get a thrill</li>
    <li>To avoid stress</li>
    <li>To not have to decide</li>
    <li>Depression</li>
  </ul>

  <h4>The Psychology Behind Procrastination</h4>
  <ul>
    <li>Avoidance</li>
    <li>Distraction </li>
    <li>Trivialisation </li>
    <li>Comparisons </li>
    <li>Humour </li>
    <li>External Blaming</li>
    <li>Reframing</li>
    <li>Denial </li>
  </ul>

  <h4>Consequences of Procrastination</h4>
  <ul>
    <li>Professional Risks </li>
    <li>Social Risks</li>
    <li>Mental Health Risks</li>
    <li>Physical Health Risks</li>
  </ul>

  <h4>CBT can support you - What is CBT?</h4>
  <ul>
    <li class="list-paragraph">Cognitive behavioural therapy (CBT) is a short-term, goal-oriented, and problem-oriented psychotherapy treatment</li>
    <li class="list-paragraph">Cognitive behavioural therapy works by changing your thought patterns, beliefs, and behavioural habits.</li>
    <li class="list-paragraph">One key tenet of CBT is that distorted thinking causes distress and problematic behaviours</li>
    <li class="list-paragraph">When chronic procrastination becomes entrenched in our behaviour patterns, we need to learn new thought processes and behaviour patterns to counteract the negative impact procrastination has in our lives.  </li>
  </ul>

  <h4>How to Beat Procrastination with CBT </h4>

  <p>
    When chronic procrastination becomes entrenched in our behaviour patterns, we need to learn new thought processes and behaviour patterns to counteract the negative impact procrastination has in our lives.
  </p>

  <ul>
    <li>Create a Prioritised To-Do List</li>
    <li>Break it Down</li>
    <li>Reframe Thoughts </li>
    <li>Challenge Your Excuses (Pros and Cons)</li>
    <li>Focus on the Positives</li>
    <li>Reward Yourself</li>
  </ul>
</div>
