<div class="banner-blog blog-header">
  <div class="container">
    <div class="blog-banner-content">
      <a class="btn btn-main" routerLink="/home">Back</a>
    </div>
  </div>
</div>

<div class="post-body-wrapper">
  <h1>Workplace Burnout</h1>
  <h2>Written by MindBerry</h2>
  <h3>19/06/2021</h3>


  <div class="post-image">
    <img src="/images/article-images/workplace-burnout.jpg" />
  </div>

  <p>
    Workplace burnout is a special type of work-related stress - a state of physical or emotional exhaustion that also involves a sense of reduced accomplishment and loss of personal identity.
  </p>
  <p>
    Burnout is often seen as an individual problem, solvable with simple techniques like “learning to say no”, more yoga, better breathing and practising resilience. However, evidence is mounting showing that personal solutions are not always enough to combat this rapidly growing workplace problem.
  </p>
  <p>
    Burnout was first described by American Psychologist Herbert Freudenberger in 1974. Since then, Burnout is now officially recognised by the World Health Organisation, and in turn, the responsibility for managing it has moved from employees to employers. Burnout is preventable, but it requires good organisational hygiene, better data, asking more timely and relevant questions, smarter budgeting and ensuring that wellness offerings are included as part of the company’s wellbeing strategy.
  </p>
  <p>
    Happy people get the best results! The world's best businesses make sure to prioritise the mental health and wellbeing of their employees, giving them the encouragement to fulfil their potential and ensure they feel confident and motivated at work.
  </p>
  <p>
    This proactive approach, helps to drive loyalty and productivity, as well as reducing sickness, absenteeism, and resignations.
  </p>
</div>
