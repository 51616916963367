import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { CounsellorService } from "../services/counsellor.service";
import { Observable, of } from "rxjs";

@Injectable()
export class ProfileResolve implements Resolve<any> {

    constructor(private counsellorService: CounsellorService, private router: Router) {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let mbNumber = route.params['mbno']
        if (mbNumber == null) {
            return null;
        }

        return new Promise<any>((resolve, reject) => {
            this.counsellorService.getProfile(mbNumber).subscribe(
                (data) => {
                    resolve(data);
                },
                e => {
                    reject(e);
                });
        }).then(function (data) {
            return data;
        }).catch(error => {
            this.router.navigate(['/404']);
            return of(null);
        });;

    }

}
