<div class="banner-main-alt contact-banner">
  <div class="comp-ban-alt">
    <div class="container">
      <div class="contact-spacer"></div>
    </div>
  </div>
</div>

<div class="container">
  <br /><br /><br />
  <div class="contact-img-sec">
    <div class="col-md-12">
      <div class="contact-img">
        <img src="/images/mb-contact-img.png" />
      </div>
    </div>

    <div class="clear"></div>
  </div>
</div>

<br />

<section>
  <div class="container">
    <div class="col-sm-10 col-sm-offset-1">
      <div class="col-sm-6">
        <div class="contact-content-sec">
          <h1>Contact Us</h1>
          <p>
            If you would like to get in touch with MindBerry, please provide your information and we will get back to you as soon as we can.
          </p>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="contact-content-sec-alt">
          <h2><strong>Get in touch</strong></h2>

          <p>
            <strong>MindBerry Group Limited,</strong><br />
            Vision House, 3a Dee Road,<br />
            Richmond, TW9 2JN
          </p>
        </div>

        <div class="contact-content-sec-alt">
          <h2><strong>Connect with us</strong></h2>

          <a class="btn-simple" href="https://www.linkedin.com/company/mindberry-group" target="_blank">linkedin</a>
        </div>
      </div>
    </div>

    <div class="clear"></div>

    <!-- CONTACT FORM -->
    <div class="col-sm-10 col-sm-offset-1" *ngIf="!isProcessed">

      <div class="mb-form-outer">
        <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm.value)">
          <div class="col-sm-12">
            <div class="mb-form-sec">
              <div class="mb-form-sec-title">Name <span>Required</span></div>
              <div class="mb-form-sec-element">
                <input type="text" [formControl]="myForm.controls['name']" [(ngModel)]="model.name"/>
              </div>

              <div class="form-error-sec" *ngIf="!myForm.controls['name'].valid && myForm.controls['name'].touched">
                Please enter your name
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mb-form-sec">
              <div class="mb-form-sec-title">Email <span>Required</span></div>
              <div class="mb-form-sec-element">
                <input type="text" [formControl]="myForm.controls['email']" [(ngModel)]="model.email"/>
              </div>

              <div class="form-error-sec" *ngIf="!myForm.controls['email'].valid && myForm.controls['email'].touched">
                Please provide your email address
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mb-form-sec">
              <div class="mb-form-sec-title">Subject <span>Required</span></div>
              <div class="mb-form-sec-element">
                <input type="text" [formControl]="myForm.controls['subject']" [(ngModel)]="model.subject"/>
              </div>

              <div class="form-error-sec" *ngIf="!myForm.controls['subject'].valid && myForm.controls['subject'].touched">
                Please enter a subject
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="mb-form-sec">
              <div class="mb-form-sec-title">Message <span>Required</span></div>
              <div class="mb-form-sec-element">
                <textarea [formControl]="myForm.controls['message']" [(ngModel)]="model.message"></textarea>
              </div>

              <div class="form-error-sec-textarea" *ngIf="!myForm.controls['message'].valid && myForm.controls['message'].touched">
                Please enter your message
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-8 col-sm-8 col-lg-offset-3 col-md-offset-2 col-sm-offset-2">
            <button type="submit" class="btn-main btn-full">SEND MESSAGE</button>
          </div>
        </form>
      </div>
    </div>

    <div class="clear"></div>

    <!-- CONTACT FORM SUCCESS -->
    <div class="panel-outer contact-panel" *ngIf="isProcessed">
      <div class="col-sm-4 col-xs-6 col-sm-offset-4 col-xs-offset-3">
        <img src="/images/icon-tick.png" />
      </div>

      <div class="clear"></div>

      <div class="panel-content">
        <h1>Your message has been <br />successfully sent</h1>

        <p>
          Thank you for your enquiry. It has been forwarded to the relevant department and will be dealt with as soon as possible.
        </p>
      </div>

      <button type="button" routerLink="/home" class="btn-main">Home</button>
    </div>

    <!-- CONTACT FORM ERROR -->
    <div class="panel-outer contact-panel" *ngIf="">
      <div class="col-sm-4 col-xs-6 col-sm-offset-4 col-xs-offset-3">
        <img src="/images/icon-cross.png" />
      </div>

      <div class="clear"></div>

      <div class="panel-content">
        <h1>There was and ERROR <br /> while submitting the form</h1>

        <p>
          Please wait and try again later.
        </p>
      </div>

      <button type="button" routerLink="/contact" class="btn-error">Try Again</button>
    </div>
  </div>
</section>
