


import {Gender} from './Gender'; 
import {CountryVM} from './CountryVM'; 
import {NationalityVM} from './NationalityVM'; 
import {TimeZoneInfoVM} from './TimeZoneInfoVM'; 
import {CounsellingTopicVM} from './CounsellingTopicVM'; 
import {CounsellorWorkingHoursVM} from './CounsellorWorkingHoursVM'; 
import {LanguageVM} from './LanguageVM'; 
import {CounsellorSessionPreferencesVM} from './CounsellorSessionPreferencesVM'; 
import {ProfessionalBookingCriteria} from './ProfessionalBookingCriteria'; 
export class CounsellorPublicProfileVM {
    
    displayName?: string;
    
    firstname?: string;
    
    lastname?: string;
    
    gender?: Gender;
    
    city?: string;
    
    postcode?: string;
    
    country?: CountryVM;
    
    profileImage?: string;
    
    nationality?: NationalityVM;
    
    timeZone?: TimeZoneInfoVM;
    
    approved?: boolean;
    
    mbNumber?: string;
    
    headline?: string;

    shortDescription?: string;

    fullDescription?: string;
    
    counsellingTopics?: CounsellingTopicVM[];
    
    workingHours?: CounsellorWorkingHoursVM[];
    
    languagesSpoken?: LanguageVM[];
    
    sessionPreferences?: CounsellorSessionPreferencesVM;
    
    qualification?: string;
    
    experience?: number;
    
    bookingCriteria?: ProfessionalBookingCriteria;
    
    organisationNo?: string;
    
    organisation?: string;
    
    organisationImage?: string;
    
}
