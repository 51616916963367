import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'myaccount',
  templateUrl: './myaccount.component.html',
})
export class MyAccountComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Sign up & sign in to MindBerry | MindBerry - Because Your Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Sign into your MindBerry account. Not registered? Create an account here.' });
    this.meta.addTag({ name: 'keywords', content: 'Log in, Sign in, Sign up, Create account' });

    window.scrollTo(0, 0);
  }
}
