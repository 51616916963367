import { Component } from "@angular/core";

@Component({
  selector: 'blogpost-procrastination',
  templateUrl: './procrastination.component.html',
})

export class BlogPostProcrastinationComponent {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
