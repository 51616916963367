<div class="banner-main home-banner">
  <div class="col-sm-6 col-sm-offset-3">
    <h1 class="page-title">Professional Directory</h1>

    <div class="col-sm-6 col-sm-offset-3 centered push-up-20 push-down-30">
      <a class="btn-main btn-full" href="https://client.mind-berry.com/" target="_blank">Login to view more</a>
    </div>
  </div>

  <div class="clear"></div>
</div>

<div class="col-lg-8 col-md-10 col-sm-10 col-lg-offset-2 col-md-offset-1 col-sm-offset-1 push-up-100">
  <div class="panel">
    <div class="search-result-sec-wrap" *ngFor="let item of results">
      <a routerLink="/profile/{{item.mbNumber}}" (click)="emitDisplayLoadingDialog(true);">

        <div class="col-md-2 col-sm-3 no-padding">
          <div class="search-result-img">
            <img src="/images/ucsession1.jpg" *ngIf="item.profileImage == null" />
            <img src="{{item.profileImage}}" *ngIf="item.profileImage != null" />
          </div>

          <div class="search-result-name">
            <div class="">{{item.mbNumber}}</div>
          </div>
        </div>

        <div class="col-md-7 col-sm-6 no-padding">
          <div class="">
            <div class="search-result-name-alt">
              {{item.displayName}} <span>
                - {{item.experience}} Years
                Experience
              </span>
            </div>
            <div class="result-headline">{{item.headline}}</div>
            <div class="search-result-sec">{{item.country.name}}</div>
          </div>
        </div>

        <div class="col-md-3 col-sm-3">
          <div class="search-result-sessions-wrap">
            <div class="search-result-open">Session Types</div>

            <div>
              <div class="col-xs-4">
                <div class="search-result-session-sec" *ngIf="!item.sessionPreferences.video">
                  <img src="/images/dash-icon-video-search.png">
                </div>

                <div class="search-result-session-sec" *ngIf="item.sessionPreferences.video">
                  <img src="/images/dash-icon-video.png">
                </div>
              </div>

              <div class="col-xs-4">
                <div class="search-result-session-sec" *ngIf="!item.sessionPreferences.audio">
                  <img src="/images/dash-icon-audio-search.png">
                </div>

                <div class="search-result-session-sec" *ngIf="item.sessionPreferences.audio">
                  <img src="/images/dash-icon-audio.png">
                </div>
              </div>

              <div class="col-xs-4">
                <div class="search-result-session-sec" *ngIf="!item.sessionPreferences.im">
                  <img src="/images/dash-icon-messa-search.png">
                </div>

                <div class="search-result-session-sec" *ngIf="item.sessionPreferences.im">
                  <img src="/images/dash-icon-messa.png">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="clear"></div>
      </a>
    </div>
  </div>
</div>

<div class="clear"></div>
