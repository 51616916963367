<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>Grow and expand your professional practice</h1>
        <p>Access a growing pool of professional clients and offer your services online using our practice management
          tools</p>
      </div>
      <div class="">
        <button class="btn-main" type="button" routerLink="/myaccount">SIGN UP</button>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/banner-pro.png" />
    </div>
  </div>
</div>

<section>
  <div class="container">
    <div class="col-sm-4">
      <div class="content">
        <h1>Be discovered by <br />more clients</h1>
        <p>
          Build your profile and let potential new clients know about your specialities and who you work best with
        </p>
      </div>

      <div class="icon-sec-left pro-sec-2-icon-sec col-sm-12 nopadding">
        <div class="col-xs-6 nopadding-left">
          <div class="col-lg-4 col-md-12 nopadding"><img src="/images/pro-icons-1.png" /></div>
          <div class="col-lg-8 col-md-12 push-down-10">Professional Profile</div>
        </div>
        <div class="col-xs-6 nopadding-left">
          <div class="col-lg-4 col-md-12 nopadding"><img src="/images/pro-icons-2.png" /></div>
          <div class="col-lg-8 col-md-12 push-down-10">Booking System</div>
        </div>

        <div class="clear"></div>

        <div class="col-xs-6 nopadding-left">
          <div class="col-lg-4 col-md-12 nopadding"><img src="/images/pro-icons-3.png" /></div>
          <div class="col-lg-8 col-md-12 push-down-10">Payment Gateway</div>
        </div>
        <div class="col-xs-6 nopadding-left">
          <div class="col-lg-4 col-md-12 nopadding"><img src="/images/pro-icons-4.png" /></div>
          <div class="col-lg-8 col-md-12 push-down-10">Communications Portal </div>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-7 col-sm-offset-1 sec-2-img">
      <img src="/images/hp-sec-1-alt.png" />
    </div>
  </div>
</section>

<section class="pro-sec-3">
  <div class="pro-sec-3-inner">
    <div class="pro-sec-3-main-outer">
      <h1>A comprehensive set of <br />practice management tools</h1>

      <div class=" pro-sec-3-text col-lg-5 col-md-5 col-sm-12 col-lg-offset-1 col-md-offset-0">
        <div class="col-sm-11 col-sm-offset-1">
          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-1.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Build a professional profile that ranks high in search results</h2>
              <p>
                Build your profile, and let potential new clients know about your specialities and who you work best
                with
              </p>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-2.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Manage your bookings and payments</h2>
              <p>
                MindBerry users are able to book and pay for sessions through your profile.
              </p>
            </div>
          </div>

          <div class="clear"></div>

          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-3.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Opt-in to work with business customers</h2>
              <p>
                Opt-in to work with MindBerry’s business customers. If we think you are a good fit for a particular
                organisation, their users will be able to book sessions with you.
              </p>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-4.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Set your prices and availability</h2>
              <p>
                You retain control of when you work and what you charge your existing customers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pro-sec-3-img col-lg-6 col-md-7 col-sm-12 nopadding">
        <img src="/images/pro-sec-3-1.png" />
      </div>

      <div class="clear"></div>
    </div>
  </div>
</section>

<br /><br />

<section class="nopadding">
  <div class="container">
    <div class="col-sm-7 pro-sec-3-img-alt">
      <img src="/images/pro-sec-3-2.png" />
    </div>

    <div class="col-sm-5">
      <div class="content">
        <h1>Hold sessions via video, audio or instant messaging</h1>
        <p>
          Host your sessions online via MindBerry's communication portal that supports instant messaging, audio and
          video.
        </p>
      </div>

      <div class="icon-sec-left pro-sec-2-icon-sec col-sm-12 nopadding">
        <div class="col-xs-4 nopadding-left">
          <div class="col-md-4 col-sm-12 nopadding"><img src="/images/pro-icons-5.png" /></div>
          <div class="col-md-8 col-sm-12 push-down-15 nopadding-mobile">Video</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-md-4 col-sm-12 nopadding"><img src="/images/pro-icons-6.png" /></div>
          <div class="col-md-8 col-sm-12 push-down-15 nopadding-mobile">Audio</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-md-4 col-sm-12 nopadding"><img src="/images/pro-icons-7.png" /></div>
          <div class="col-md-8 col-sm-12 push-down-15 nopadding-mobile">Message</div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>

<section class="pro-sec-4-outer">
  <div class="pro-sec-4-inner">
    <div class="container">
      <div class="sec-4">
        <h1>Start growing your practice today</h1>

        <br />
        <button class="btn-main" type="button" routerLink="/myaccount">SIGN UP</button>
        <br />

      </div>
    </div>
  </div>
</section>
