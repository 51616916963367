<div class="banner-blog blog-header">
  <div class="container">
    <div class="blog-banner-content">
      <h1>News & Articles</h1>
    </div>
  </div>
</div>

<div class="container">
  <div class="blog-posts-wrapper">
    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long <br />
          <span>30/01/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>

    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/2110951/pexels-photo-2110951.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long. but this one is longer. <br />
          <span>14/02/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>

    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/1998479/pexels-photo-1998479.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long <br />
          <span>10/04/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>

    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/2011824/pexels-photo-2011824.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long <br />
          <span>11/05/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>

    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/1616403/pexels-photo-1616403.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long <br />
          <span>30/01/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>

    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/2110951/pexels-photo-2110951.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long. but this one is longer. <br />
          <span>14/02/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>

    <div class="blog-post-card">
      <div class="blog-post-card-image">
        <img src="https://images.pexels.com/photos/1998479/pexels-photo-1998479.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" />
      </div>

      <div class="blog-post-card-content">
        <h2>
          This is the Title of the post. its quite long <br />
          <span>10/04/2021</span>
        </h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In pretium blandit posuere. Vestibulum ullamcorper purus malesuada mollis blandit.
        </p>

        <a routerLink="/article-post">View more</a>
      </div>
    </div>
  </div>
</div>
