<div class="banner-main home-banner">
  <div class="col-sm-6 col-sm-offset-3">
    <h1 class="page-title">Professional Profile</h1>

    <div class="col-sm-6 col-sm-offset-3 centered push-up-20 push-down-30">
      <a class="btn-main btn-full" href="https://client.mind-berry.com/" target="_blank">Login to view profile</a>
    </div>
  </div>

  <div class="clear"></div>
</div>

<!-- profile picture and details -->
<div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2 push-down-15">

  <div class="profile-image">
    <img src="/images/ucsession1.jpg" *ngIf="profileModel.profileImage == null" alt="{{profileModel.displayName}}" />
    <img src="{{profileModel.profileImage}}" *ngIf="profileModel.profileImage != null"
      alt="{{profileModel.displayName}}" />
  </div>

  <div class="panel">
    <div class="col-sm-12">
      <div class="col-sm-12">
        <div class="profile-name push-down-15">{{profileModel.firstname}} {{profileModel.lastname}}</div>
      </div>

      <div class="clear"></div>

      <div class="push-down-10">
        <div class="col-md-6">
          <div class="profile-sec-wrap">
            <div class="profile-title">MB Number</div>
            <div class="profile-content">{{profileModel.mbNumber}}</div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="profile-sec-wrap">
            <div class="profile-title">Display Name</div>
            <div class="profile-content">{{profileModel.displayName}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Professional information -->
<div class="col-md-8 col-sm-10 col-md-offset-2 col-sm-offset-1">
  <div class="panel">
    <div class="panel-title">About Me</div>
    <div class="col-sm-12">
      <div class="profile-sec-wrap">
        <div class="profile-content-alt">
          <div class="col-sm-12">
            <div class="general-content" [innerHtml]="profileModel.fullDescription | newline"></div>

            <div class="general-content"></div>
          </div>

          <div class="clear"></div>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="profile-sec-wrap">
        <div class="profile-title">Areas of Expertise</div>
        <div class="profile-content-alt">
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-6" *ngFor="let item of profileModel.counsellingTopics">
            <div class="profile-content">{{item.name}}</div>
          </div>

          <div class="clear"></div>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="profile-sec-wrap">
        <div class="profile-title">Languages</div>
        <div class="profile-content-alt">
          <div class="col-sm-4 col-xs-6" *ngFor="let item of profileModel.languagesSpoken">
            <div class="profile-content">{{item.name}}</div>
          </div>

          <div class="clear"></div>
        </div>

        <div class="clear"></div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="profile-sec-wrap">
        <div class="profile-title">Location</div>
        <div class="profile-content-alt">
          <div class="profile-content">{{profileModel.city}}, {{profileModel.country.name}}</div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="profile-sec-wrap">
        <div class="profile-title">Nationality</div>
        <div class="profile-content-alt">
          <div class="profile-content">{{profileModel.nationality.name}}</div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="profile-sec-wrap">
        <div class="profile-title">Local Time Zone</div>
        <div class="profile-content-alt">
          <div class="profile-content">{{profileModel.timeZone.zoneName}}</div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</div>

<!-- Session Types -->
<div class="col-md-8 col-sm-10 col-md-offset-2 col-sm-offset-1">
  <div class="panel">
    <div class="panel-title">Available Session Types</div>

    <div class="">
      <div class="col-sm-4" *ngIf="profileModel.sessionPreferences.video">
        <div class="profile-sec-wrap">
          <div class="type-sec-icon type-icon-2"><img src="/images/dash-icon-video.png"></div>
          <div class="profile-content">Video</div>
        </div>
      </div>
      <div class="col-sm-4" *ngIf="profileModel.sessionPreferences.audio">
        <div class="profile-sec-wrap">
          <div class="type-sec-icon type-icon-3"><img src="/images/dash-icon-audio.png"></div>
          <div class="profile-content">Audio</div>
        </div>
      </div>
      <div class="col-sm-4" *ngIf="profileModel.sessionPreferences.im">
        <div class="profile-sec-wrap">
          <div class="type-sec-icon type-icon-4"><img src="/images/dash-icon-messa.png"></div>
          <div class="profile-content">Instant Messaging</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Session Prices -->
<div class="col-md-8 col-sm-10 col-md-offset-2 col-sm-offset-1">
  <div class="panel">
    <div class="panel-title">Session Prices</div>

    <div class="col-sm-4 col-xs-12">
      <div class="profile-sec-wrap">
        <div class="panel-sub-title">25 Minutes</div>

        <div class="panel-sub-content">
          Recommended for initial <br />consultations and check-ups
        </div>

        <div class="profile-content-alt">
          {{this.profileModel.bookingCriteria != 3 && profileModel.sessionPreferences.session25Valid ? ('£ ' + profileModel.sessionPreferences.session25Price) : 
          (profileModel.sessionPreferences.session25Valid) ? 'Available' : 'Unavailable' }}
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-xs-12">
      <div class="profile-sec-wrap">
        <div class="panel-sub-title">50 Minutes</div>

        <div class="panel-sub-content">
          Recommended for a <br />more in-depth session
        </div>

        <div class="profile-content-alt">
          {{this.profileModel.bookingCriteria != 3 && profileModel.sessionPreferences.session50Valid ? '£ ' + profileModel.sessionPreferences.session50Price : 
          (profileModel.sessionPreferences.session50Valid) ? 'Available' : 'Unavailable' }}
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-xs-12">
      <div class="profile-sec-wrap">
        <div class="panel-sub-title">80 Minutes</div>

        <div class="panel-sub-content">
          Recommended for a <br />more in-depth session
        </div>

        <div class="profile-content-alt">
          {{this.profileModel.bookingCriteria != 3 && profileModel.sessionPreferences.session80Valid ? '£ ' + profileModel.sessionPreferences.session80Price : 
          (profileModel.sessionPreferences.session80Valid) ? 'Available' : 'Unavailable' }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Calendar -->
<div class="col-md-8 col-sm-10 col-md-offset-2 col-sm-offset-1 push-up-60">
  <div class="panel">
    <div class="panel-title">Availability</div>
    <div class="panel-sub-title">Select your desired session date and time.</div>

    <div class="false-calendar">
      <div class="false-login">
        <p>You must be logged in to book a <br />session with this professional.</p>
        <br />
        <a class="btn-main btn-full" href="https://client.mind-berry.com/" target="_blank">Login</a>
      </div>
    </div>
  </div>
</div>


<div class="clear"></div>
