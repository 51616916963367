import { Pipe, PipeTransform } from '@angular/core';
import { SessionType } from '../models/SessionType';

@Pipe({ name: 'sessiontype' })
export class SessionTypePipe implements PipeTransform {

    transform(value: number, args: string[]): any {
        switch (value) {
            case SessionType.video:
                return 'Video';
            case SessionType.audio:
                return 'Audio';
            case SessionType.im:
                return 'Instant Messaging';
            case SessionType.faceToFace:
                return 'Face to Face';
            default:
                return 'N/A';
        }
    }

}