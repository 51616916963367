import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CounsellorPublicProfileVM } from "../models";
import {share, tap, map} from 'rxjs/operators';

@Injectable()
export class CounsellorService {

  constructor(private http: HttpClient) {
  }

  public getProfile(mbNumber: string): Observable<CounsellorPublicProfileVM> {
    // TODO: RECHECK
    debugger;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get('/api/profile/' + mbNumber, { headers }).pipe(
      map(resp => resp as CounsellorPublicProfileVM));
  }



}
