<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>Emergency <br />Contacts</h1>
        <p>Need help now?</p>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/mb-contact-img.png" />
    </div>
  </div>
</div>

<section>
  <div class="container push-down-30">
    <div class="comp-sec-1-title col-lg-6 col-md-8 col-sm-10 col-lg-offset-3 col-md-offset-2 col-sm-offset-1 panel-bg">
      <p>
        You should not use this website in emergency. If you are in a life-threatening situation, please refer to the resources below and contact them for immediate help.
      </p>
    </div>
  </div>
</section>

<div class="container">
  <div class="col-lg-6 col-md-8 col-sm-10 col-lg-offset-3 col-md-offset-2 col-sm-offset-1 push-down-20">
    <p class="comp-sec-1-title"><strong>If you require the emergency services, contact the following:</strong></p>
  </div>
</div>

<div class="container nopadding">
  <div class="col-sm-6">
    <div class="contact-list-sec">
      <h4><strong>Helplines</strong></h4>
      <ul>
        <li><a href="https://www.samaritans.org/how-we-can-help/contact-samaritan/?gclid=CjwKCAiAob3vBRAUEiwAIbs5TuxUIXl42RTCp0AhjEDFEEc3TpnC5odeXQg2OhS4qwUkQh-n3Tf_BxoCRGIQAvD_BwE" target="_blank">Samaritans (suicide helpline) <br /> <strong>Call 116 123</strong><span>Visit Site</span></a></li>
        <li><a href="https://www.childline.org.uk/get-support/?utm_source=google&utm_medium=cpc&utm_campaign=UK_GO_S_B_BND_Grant_Childline_Contact&utm_term=calling_childline&gclsrc=aw.ds&&gclid=CjwKCAiAob3vBRAUEiwAIbs5TvvVVIUd8gW6cfy7FLUhYiUi1PyE7YPG1Z3DvKoTEIQptoXRFbX-dhoCJ8IQAvD_BwE&gclsrc=aw.ds" target="_blank">ChildLine (ages 19 and below) <br /> <strong>Call 0800 1111</strong><span>Visit Site</span></a></li>
        <li><a href="https://www.lifelinehelpline.info/" target="_blank">LifeLine (Northern Ireland) <br /> <strong>Call 0808 808 8000</strong><span>Visit Site</span></a></li>
        <li><a href="https://nopanic.org.uk/" target="_blank">No Panic (anxiety helpline) <br /> <strong>Call 0844 967 4848</strong><span>Visit Site</span></a></li>
        <li><a href="https://switchboard.lgbt/" target="_blank">Switchboard (LGBT+) <br /> <strong>Call 0300 330 0630</strong><span>Visit Site</span></a></li>
        <li><a href="https://www.nightline.ac.uk/" target="_blank">Nightline (students) <br /> <strong>Call 0207 632 0101</strong><span>Visit Site</span></a></li>
      </ul>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="">
      <div class="contact-list-sec">
        <h4><strong>Emergency Numbers</strong></h4>
        <ul>
          <li>Emergency Services <span>Call 999</span></li>
          <li>Police <span>Call 999</span></li>
          <li>Fire department <span>Call 999</span></li>
          <li><a href="https://111.nhs.uk/" target="_blank">NHS (health advice only) <br /> <strong>Call 111</strong><span>Visit Site</span></a></li>
        </ul>
      </div>
    </div>

    <div class="">
      <div class="contact-list-sec">
        <h4><strong>International Numbers</strong></h4>
        <ul>
          <li><a href="https://en.wikipedia.org/wiki/List_of_suicide_crisis_lines" target="_blank">List of international suicide hotlines <span>Visit Site</span></a></li>
          <li><a href="https://yourlifecounts.org/" target="_blank">Your Life Counts <span>Visit Site</span></a></li>
          <li><a href="https://yourlifecounts.org/find-help/" target="_blank">Your Life Counts (helpline directory) <span>Visit Site</span></a></li>
        </ul>
      </div>
    </div>
  </div>
</div>


<br /><br /><br />
