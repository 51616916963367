import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'professionals',
  templateUrl: './professionals.component.html',
})
export class ProfessionalsComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Grow Your Business - Mental Coaches & Therapists | MindBerry');
    this.meta.addTag({ name: 'description', content: "Grow your mental health or coaching practice. Reach more customers & work online. Use MindBerry's suite of practice management tools. Learn more!" });
    this.meta.addTag({ name: 'keywords', content: 'Coaching practice management, Counselling practice management, Therapy work online, Therapist work online, Coaching booking system, Counselling booking system, Psychotherapy booking system, Therapist booking system, Coaching work online, Psychotherapy work online' });

    window.scrollTo(0, 0);
  }
}
