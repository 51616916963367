<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>Manage your team of mental health and coaching professionals online</h1>
        <p>
          Take your online business to the next level with MindBerry for Business
        </p>
      </div>
      <div class="">
        <button class="btn-main" type="button" routerLink="/business-form">BOOK A DEMO</button>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/for-business-ban-img.png" />
    </div>
  </div>
</div>

<section class="business-sec-2-wrap">
  <div class="content centered">
    <h1>Ideal for</h1>
  </div>

  <br />

  <div class="container">
    <div class="col-sm-4">
      <div class="business-sec-2-img"><img src="/images/business-sec-2-img-2.png" /></div>
      <div class="business-sec-2-content">
        Insurers
      </div>
    </div>

    <div class="col-sm-4">
      <div class="business-sec-2-img"><img src="/images/business-sec-2-img-1.png" /></div>
      <div class="business-sec-2-content">
        Mental health & <br />coaching businesses
      </div>
    </div>

    <div class="col-sm-4">
      <div class="business-sec-2-img"><img src="/images/business-sec-2-img-3.png" /></div>
      <div class="business-sec-2-content">
        Charities
      </div>
    </div>
  </div>
</section>

<section class="pro-sec-3">
  <div class="pro-sec-3-inner">
    <div class="pro-sec-3-main-outer">
      <h1>A comprehensive set of <br />practice management tools</h1>

      <div class=" pro-sec-3-text col-lg-5 col-md-5 col-sm-12 col-lg-offset-1 col-md-offset-0">
        <div class="col-sm-11 col-sm-offset-1">
          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-1.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Manage your clients</h2>
              <p>
                Invite your clients to sign up and issue them with redeemable session credits that enable them to book time with the mental health or coaching professionals working for your business.
              </p>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-2.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Manage your professionals</h2>
              <p>
                Invite your team of professionals to join your account and create their individual profiles. Manage how they work by configuring the length and types of session they can offer.
              </p>
            </div>
          </div>

          <div class="clear"></div>

          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-3.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Create professional profiles for your workforce</h2>
              <p>
                Each of your professionals creates their own customisable profile. Each profile includes key information and a booking system through which professionals can manage their availability and appointments.
              </p>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-6 pro-sec-3-content">
            <div class="col-md-2 nopadding">
              <div class="pro-sec-3-img-icon">
                <img src="/images/pro-icons-num-4.png" />
              </div>
            </div>

            <div class="col-md-10">
              <h2>Dedicated communications portal</h2>
              <p>
                The MindBerry communications portal supports video, audio and instant messaging. Unlike other online communication platforms, MindBerry has been specifically designed with mental health and coaching in mind.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="pro-sec-3-img col-lg-6 col-md-7 col-sm-12 nopadding">
        <img src="/images/business-dashboard.png" />
      </div>

      <div class="clear"></div>
    </div>
  </div>
</section>

<br /><br />

<section class="nopadding">
  <div class="container">
    <div class="col-sm-7 pro-sec-3-img-alt">
      <img src="/images/pro-sec-3-2.png" />
    </div>

    <div class="col-sm-5">
      <div class="content">
        <h1>Go digital with zero on premise infrastructure</h1>
        <p>
          MindBerry enables the digital transformation of your organisation without the need for expensive and complex technology. Our cloud based platform offers you the security and scalability you need to take your business online.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="comp-sec-2-outer">
  <div class="container">
    <div class="comp-last-sec">
      <div class="content">
        <h1>Go online and grow your business</h1>

        <button class="btn-main" type="button" routerLink="/business-form">BOOK A DEMO</button>
      </div>
    </div>
  </div>
</section>
