import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { ContactVM, B2BContactVM } from '../../models/models';

@Component({
  selector: 'business-form',
  templateUrl: './business-form.component.html',
})
export class BusinessFormComponent {

  model: B2BContactVM = new B2BContactVM;
  myForm: FormGroup;
  isProcessed: boolean = false;
  success: boolean = false;

  constructor(private router: Router, private titleService: Title, private meta: Meta, private fb: FormBuilder, private contactService: ContactService) {

  }

  ngOnInit() {
    this.titleService.setTitle('Book a demo | MindBerry - Because The Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Book a demo and discover how you can benefit from the MindBerry platform' });
    this.meta.addTag({ name: 'keywords', content: 'Mental health demo, Mental wellbeing demo, Therapy demo, Counselling demo, Coaching demo, Psychotherapy demo, Mental health, Mental wellbeing, Coaching, Psychotherapy' });

    window.scrollTo(0, 0);

    this.myForm = this.fb.group({
      name: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required]),
      contactNumber: new FormControl('', Validators.required),
      serviceOfInterest: new FormControl('', Validators.required),
      noOfEmployees: new FormControl('', Validators.required),
      additionalInfo: new FormControl(''),
      wheredidyoufindus: new FormControl('')
    });
  }

  onSubmit() {
    this.markAsTouched(this.myForm);
    if (!this.myForm.valid) {
    }
    else {
      this.contactService.B2BContact(this.model)
        .subscribe(
          r => {
            if (r !== undefined && r.ok) {
              this.success = true;
              this.isProcessed = true;
            }
          },
          e => {
            this.success = false;
            this.isProcessed = true;
          }
        );
    }
  }

  markAsTouched(group: FormGroup | FormArray) {
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  resolved(event: any) {
    alert('ok');
  }
}
