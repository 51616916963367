<section class="about-banner">
  <div class="container push-down-120">
    <div class="comp-sec-1-title col-lg-6 col-md-8 col-sm-10 col-lg-offset-3 col-md-offset-2 col-sm-offset-1 push-up-90">
      <div class="push-up-40">
        <h1 class="centered">It didn’t just <br />happen overnight. </h1>
        <p>
          Time and effort has gone into making MindBerry work as a technology platform for psychotherapy, counselling
          and coaching.
        </p>

        <p>
          Start your journey with us today!
        </p>
      </div>
    </div>

    <div class="clear"></div>
  </div>
</section>


<section class="pro-sec-3">
  <div class="pro-sec-3-inner">
    <div class="pro-sec-3-main-outer">
      <h1 class="centered push-up-20">Our Founders & Team</h1>

      <div class="container">
        <div class="about-outer">
          <div class="about-sec-l about-panels">
            <div class="about-img"><img src="/images/about-team-6.jpg" /></div>
            <div class="about-info">
              <h3>Hugo Phillips</h3>
              <h2>Managing Director</h2>
              <p>
                Hugo is a board-level executive who has worked across a number of industry sectors, including health
                tech, digital technology, marketing, financial services and oil and gas engineering, using cutting-edge
                technology and SaaS to design and deliver products that aim to give the best possible experience and
                outcomes for the end user. Over the course of his career he has become increasingly aware of the need to
                address mental health issues, and the importance of supporting mental wellbeing in the workplace if
                individuals and companies are to thrive. He is delighted to have joined MindBerry at this exciting point
                on its journey.
              </p>
            </div>
          </div>
          <div class="about-sec-r about-panels">
            <div class="about-img"><img src="/images/about-team-1.jpg" /></div>
            <div class="about-info">
              <h3>Sandra de Monte</h3>
              <h2>Head of Client Relations</h2>
              <p>
                Sandra is a qualified, BACP registered Integrative Psychotherapist and certified Online Counsellor who
                founded MindBerry in 2016. Sandra completed her training at Regent's University London and also holds a
                certification in Global Health from HarvardX. In addition to her private practice, Sandra's experience
                includes holding the position of Associate Counsellor at the Royal College of Art, London and delivering
                successful mental wellbeing workshops for large companies such as eBay, Google, Thunes and many more.
              </p>
            </div>
          </div>
        </div>

        <div class="about-outer">
          <div class="about-sec-l about-panels">
            <div class="about-img"><img src="/images/about-team-2.jpg" /></div>
            <div class="about-info">
              <h3>Christian Mogele</h3>
              <h2>Head of Operations</h2>
              <p>
                Christian helped to found MindBerry in 2016 whilst studying for his Masters degree. He holds a BSc
                (honours) in psychology and graduated from Oxford University receiving a postgraduate certificate in
                psychodynamic counselling. His clinical experience working in the NHS & mental health background
                continues to inform MindBerry's development whilst he takes on other roles & responsibilities within the
                company. Christian also holds a diploma in online counselling from OCST.
              </p>
            </div>
          </div>
          <div class="about-sec-r about-panels">
            <div class="about-img"><img src="/images/about-team-10.jpg" /></div>
            <div class="about-info">
              <h3>Liz Hamlet</h3>
              <h2>Head of Engagement</h2>
              <p>
                Liz Hamlet is the founder of Spark Succeed Coaching & Consulting. Liz is a coach, strategist, speaker and
                podcaster. Spark’s mission is to help leaders lead better by sparking possibility, motivation and momentum.
                In addition to MindBerry, some of the corporate clients she already works with and provides coaching and
                mentoring to: Virgin Start Up, Fujifilm, MunichRE, General Assembly, and University of Birmingham. Liz will
                work closely with management to build up an Engagement Team to support Professionals, on going training and
                continuous engagement. She will also support MindBerry in developing training content and feedback loop.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</section>
