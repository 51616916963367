import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacypolicy.component.html',
})
export class PrivacyPolicyComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Privacy Policy | MindBerry - Because Your Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Your privacy matters. Read our privacy policy here.' });
    this.meta.addTag({ name: 'keywords', content: 'Privacy policy, Mental health privacy, Coaching privacy' });

    window.scrollTo(0, 0);
  }
}
