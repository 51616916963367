import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'directory',
  templateUrl: './directory.component.html',
})
export class DirectoryComponent {

  results: any[];
  totalFound: number = 0;

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Directory | MindBerry - Because The Mind Matters');
    this.meta.addTag({ name: 'description', content: 'Directory' });
    this.meta.addTag({ name: 'keywords', content: 'Learning platform, Coaching for business, Executive coaching, Mental health at work, Counselling at work, Business psychology, Organisational psychology, Mental health platform, Coaching platform' });

    window.scrollTo(0, 0);
  }
}
