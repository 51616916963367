export class ContactVM {
    public name: string;
    public email: string;
    public subject: string;
    public message: string;
}

export class B2BContactVM {
  name: string;
  companyName: string;
  email: string;
  contactNumber: string;
  serviceOfInterest: string;
  noOfEmployees: string;
  additionalInfo: string;
  wheredidyoufindus: string;
}
