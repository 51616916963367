<div class="banner-main-alt article-banner">
  <div class="comp-ban-alt">
    <div class="container">
      <div class="col-lg-5 col-md-6 col-sm-6 nopadding">
        <div class="banner-content">
          <h1>Improve the mental health and wellbeing of your workplace</h1>
          <br />
          <p>MindBerry helps organisations support staff with their wellbeing and mental health. Our platform makes it
            easy and affordable to access counselling and coaching, anywhere at anytime. Sessions take place online via
            MindBerry’s video, audio and instant messaging technology.</p>
          <br />
        </div>
        <div class="banner-btn">
          <button class="btn-main" type="button" routerLink="/business-form">BOOK A DEMO</button>
        </div>
      </div>
      <div class="col-lg-7 col-md-6 col-sm-6">
        <img class="work-ban" src="/images/work-ban-img.png" />
      </div>
    </div>
    <br /><br /><br /><br />
  </div>
</div>

<div class="container">
  <div class="panel-mid-rounded covid-panel-bg">
    <div class="panel-mind-inner">
      <h2>COVID-19 - Supporting a remote workforce</h2>
      <p>
        Stress, anxiety, isolation, depression and feelings of helplessness are just a few of the challenges faced by
        employees during lock downs and the ongoing corona virus pandemic. MindBerry helps businesses look after and
        support their staff wherever they are and whatever the circumstances.
      </p>
      <p>
        <button class="btn-main" type="button" routerLink="/covid-19">READ MORE</button>
      </p>
    </div>
  </div>
</div>

<div class="container">
  <div class="comp-sec-1-title col-lg-8 col-md-8 col-sm-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-1">
    <div class="content-alt push-up-40">
      <h1>The Impact of poor <br />wellbeing & mental health</h1>
      <p>
        Poor wellbeing and mental health cost your organisation. Improve the wellbeing, productivity and performance of
        your business, whilst reducing the risks and costs associated with poor mental health and professional
        stagnation.
      </p>
    </div>
  </div>

  <div class="clear"></div>

  <div class="stat-sec-wrap-outer">
    <div class="col-sm-4">
      <div class="stat-sec-wrap">
        <div class="stat-content-sec">
          <div class="stat-icon-sec stat-icon-1">
            <img src="/images/comp-icons-1.png" />
          </div>

          <p>
            91 million workdays are lost due to mental illness
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="stat-sec-wrap">
        <div class="stat-content-sec">
          <div class="stat-icon-sec stat-icon-2">
            <img src="/images/comp-icons-2.png" />
          </div>

          <p>
            The average cost of replacing a team member is £30,000
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="stat-sec-wrap">
        <div class="stat-content-sec">
          <div class="stat-icon-sec stat-icon-3">
            <img src="/images/comp-icons-3.png" />
          </div>

          <p>
            The average cost of poor mental health is £1,481 per employee
          </p>
        </div>
      </div>
    </div>

    <div class="clear"></div>

    <div class="col-sm-4">
      <div class="stat-sec-wrap">
        <div class="stat-content-sec">
          <div class="stat-icon-sec stat-icon-4">
            <img src="/images/comp-icons-4.png" />
          </div>

          <p>
            Poor relationships with clients and colleagues
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="stat-sec-wrap">
        <div class="stat-content-sec">
          <div class="stat-icon-sec stat-icon-5">
            <img src="/images/comp-icons-5.png" />
          </div>

          <p>
            Loss of motivation and commitment
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="stat-sec-wrap">
        <div class="stat-content-sec">
          <div class="stat-icon-sec stat-icon-6">
            <img src="/images/comp-icons-6.png" />
          </div>

          <p>
            Deteriorating mental and physical wellbeing
          </p>
        </div>
      </div>
    </div>

    <div class="clear"></div>
  </div>
</div>

<section class="work-sec-2-bg">
  <div class="work-sec-2-bg-inner">
    <div class="work-sec-2-main-outer">
      <div class="container work-vid-outer">
        <a name="video"></a>
        <div class="col-lg-6 col-md-6 col-sm-6 pro-sec-3-img nopadding">
          <div class="content">
            <h1>Mindful employers & organisations</h1>
            <p>
              MindBerry helps you to create a supportive culture within your organisation, improving the wellbeing and
              mental health of your workforce. We understand that each organisation is different and has its own
              challenges with employee wellbeing and mental health.
            </p>

            <p>
              At MindBerry, we believe in building a close relationship with your organisation’s HR and wider management
              to ensure we can provide you with the the most effective support.
            </p>

            <p>
              <button class="btn-main" type="button" routerLink="/business-form">BOOK A DEMO</button>
            </p>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6">
          <div class="work-vid">
            <div class="comp-vid">
              <iframe width="480" height="385"
                src="https://www.youtube-nocookie.com/embed/0AslnkDJA2I?controls=1&rel=0&enablejsapi=1" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>

<section class="testimonial-bg">
  <div class="container">
    <h1 class="centered">What our <br />customers say</h1>

    <div class="testimonial-sec">
      <div class="testimonial-img-left">
        <img src="/images/testimonial-img-1.jpg" />
      </div>

      <div class="testimonial-content-wrap-left">
        <div class="testimonial-content-title">
          <strong>Flavia Fasani</strong>
          <br />
          Director, Client Advisory, TwinFocus
        </div>
        <div class="testimonial-contact">
          <p>
            <strong>"</strong>MindBerry surpassed my expectations by far. I never thought I would be able to embrace new
            technology for this type of thing, but it was actually the same as going to therapy minus the logistics of
            travelling and facing the world straight afterwards. I was already home and that's priceless. Great
            idea.<strong>"</strong>
          </p>
        </div>
      </div>
    </div>

    <div class="testimonial-sec">
      <div class="testimonial-img-right">
        <img src="/images/testimonial-img-2.jpg" />
      </div>

      <div class="testimonial-content-wrap-right">
        <div class="testimonial-content-title">
          <strong>Steve Partos</strong>
          <br />
          Head of People, Urban
        </div>
        <div class="testimonial-contact">
          <p>
            The support and ease that comes along with MindBerry took away any anxiety and concerns about doing my sessions
            remotely. Being able to work on my self-awareness as a leader, reviewing my strengths and identifying areas I
            need to focus on was a big winner.
          </p>
        </div>
      </div>
    </div>

    <div class="clear"></div>
  </div>
</section>

<section class="work-sec-2-bg">
  <div class="work-sec-2-bg-inner">
    <div class="work-sec-2-main-outer">
      <div class="container work-vid-outer">
        <div class="comp-sec-1-title col-lg-8 col-md-8 col-sm-10 col-lg-offset-2 col-md-offset-2 col-sm-offset-1">
          <div class="content">
            <h1>The MindBerry dashboard</h1>
            <p>
              Add and remove users from your account and allocate them with session credits that can be used at your
              employee’s discretion. Analytics help you keep track of how your employees are engaging with MindBerry in
              a private and anonymised way.
            </p>
          </div>
        </div>

        <div class="work-dash-img">
          <img src="/images/work-dashboard.png" />
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>

<div class="">
  <div class="container">
    <div class="col-sm-5 sec-2-img pull-right">
      <div class="work-sec-4-img">
        <img src="/images/work-sec-4-img-1.png" />
      </div>
    </div>

    <div class="mobile-clear"></div>

    <div class="col-sm-4 col-sm-offset-2">
      <div class="content">
        <h1>Unlike the rest</h1>
        <p>
          MindBerry is unlike other online wellbeing and mental health products for corporates. We connect your staff
          with real people in real-time so that they can focus on things that are specific to them, without having to
          rely on one-size-fits-all solutions.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="">
  <div class="container">
    <div class="col-sm-5 col-sm-offset-2 sec-2-img">
      <div class="work-sec-4-img">
        <img src="/images/work-sec-4-img-2.png" />
      </div>
    </div>

    <div class="mobile-clear"></div>

    <div class="col-sm-4">
      <div class="content">
        <h1>Mental wellbeing</h1>
        <p>
          We all have mental health and looking after it is as important as looking after our physical health. Help your
          employees tackle personal challenges by letting them work with qualified mental health professionals when they
          need to.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="">
  <div class="container">
    <div class="col-sm-5 col-sm-offset-1 sec-2-img pull-right">
      <div class="work-sec-4-img">
        <img src="/images/work-sec-4-img-3.png" />
      </div>
    </div>

    <div class="mobile-clear"></div>

    <div class="col-sm-4 col-sm-offset-2">
      <div class="content">
        <h1>Coaching</h1>
        <p>
          Mental wellbeing is linked to self-esteem and the way we feel about our lives. This can relate to how we
          progress and develop at work. Help your staff to keep growing by giving them the option to work with
          experienced executive and life coaches.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="">
  <div class="container">
    <div class="col-sm-5 col-sm-offset-2 sec-2-img">
      <div class="work-sec-4-img">
        <img src="/images/work-sec-4-img-4.png" />
      </div>
    </div>

    <div class="mobile-clear"></div>

    <div class="col-sm-4">
      <div class="content">
        <h1>Workshops</h1>
        <p>
          MindBerry for Work customers also benefit from educational workshops on key topics such as mental wellbeing,
          personal development and more. Whether online or onsite, we’ll find you the right MindBerry professional for
          your needs.
        </p>
      </div>
    </div>
  </div>
</div>

<section class="comp-sec-2-outer">
  <div class="container">
    <div class="comp-last-sec">
      <div class="content">
        <h1>When your people grow, <br />your business grows</h1>

        <button class="btn-main" type="button" routerLink="/business-form">BOOK A DEMO</button>
      </div>

      <div class="icon-sec-left pro-sec-2-icon-sec col-sm-6 col-sm-offset-3 nopadding">
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12 nopadding"><img src="/images/hp-icons-1.png" /></div>
          <div class="col-sm-12 push-down-1 nopadding-mobile">Mental <br />Health</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12 nopadding"><img src="/images/hp-icons-2.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile">Life <br />Coaching</div>
        </div>
        <div class="col-xs-4 nopadding-left">
          <div class="col-sm-12 nopadding"><img src="/images/hp-icons-3.png" /></div>
          <div class="col-sm-12 push-down-10 nopadding-mobile">Executive <br />Coaching</div>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</section>
