<div class="banner-main-alt error-banner">
  <div class="error-ban-alt">
    <div class="container">
      <br /><br /><br /><br />
    </div>
  </div>
</div>

<div class="error-img">
  <img src="/images/error-img.png" />
</div>

<section>
  <div class="error-sec-outer">
    <h1>Page Not Found!</h1>

    <p>
      We can't seem to find the page you're looking for
    </p>

    <ul>
      <li>Here are some helpful links:</li>
      <li><br /></li>
      <li><a class="btn-simple" routerLink="/home">Home</a></li>
      <li><a class="btn-simple" routerLink="/professionals">Professionals</a></li>
      <li><a class="btn-simple" routerLink="/companies">Companies</a></li>
      <li><a class="btn-simple" routerLink="/myaccount">My Account</a></li>
      <li><a class="btn-simple" routerLink="/contact">Contact Us</a></li>
    </ul>
  </div>
</section>

<br /><br />
