import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ethical-standards',
  templateUrl: './ethical-standards.component.html',
})
export class EthicalStandardsComponent {
  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.titleService.setTitle('Ethical Satndards');
    this.meta.addTag({ name: 'description', content: 'Ethical Satndards' });
    this.meta.addTag({ name: 'keywords', content: 'Ethical Satndards' });

    window.scrollTo(0, 0);
  }
}
