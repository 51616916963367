<div class="banner-main home-banner">
  <div class="container">
    <div class="col-lg-5 col-md-6 col-sm-6">
      <div class="banner-content">
        <h1>Ethical <br />Standards</h1>
      </div>
    </div>
    <div class="col-lg-6 col-md-5 col-sm-5">
      <img src="/images/ethical-image.png" />
    </div>
  </div>
</div>


  <div class="container">
    <div class="comp-sec-1-title col-lg-6 col-md-8 col-sm-10 col-lg-offset-3 col-md-offset-2 col-sm-offset-1">
      <div class="content">
        <h1>Ethical Framework & Principles of Professional Conduct</h1>
      </div>
    </div>
  </div>

  <section>
    <div class="container">
      <div class="legal-content">
        <h2>Introduction</h2>

        <p>
          This Ethical Framework & Principles of Professional Conduct document was created by the MindBerry Team and approved by the Professional Advisory Board to ensure MindBerry Professionals commit to and aspire toward the delivery of the highest standards of service whether working online or face-to-face practice.
        </p>
        <p>
          This document may be referred back to by the MindBerry Team, Professional Advisory Board and any other committee that is yet to be created, should allegations arise which may impair a MindBerry Professional’s fitness to practice.
        </p>
        <p>
          The Ethical Framework & Principles of Professional Conduct applies equally to all MindBerry Professionals.
        </p>
        <p>
          The Ethical Framework is not exhaustive and covers  a range of contexts, such as internet, telephone and other electronic transmissions.
        </p>
        <p>
          It is not possible for the Ethical Framework & Principles of Professional Conduct to include every possible scenario in which an ethical breach can occur, hence a MindBerry Professional’s decisions will depend on their own evaluation and judgments.
        </p>
        <p>
          If a MindBerry Professional is called upon to defend their actions in the face of charges of unethical conduct, explinations and defences that refer to a “lack of awareness” or “misunderstanding” of the Ethical Framework will be considered invalid.
        </p>
        <p>
          For clarification, the term “Professional” refers to as Psychotherapists, Counsellors, Coaches and any other professionals who are MindBerry customers and members of MindBerry’s Professional network. Additionally, the professional terms “Therapy”, “Counselling” and “Coaching” are referred to as “Professional Practice ” in this document. Furthermore, the third person plural pronoun is used as non-gendered pronoun for “Therapist”: this mean that “they” is used for “he/she” and “their” for “his/her”.
        </p>
        <p>
          MindBerry Professionals understand that meeting and striving toward ethical practice and conduct, may involve making difficult decisions that require a degree of professionalism, discipline and courage.
        </p>

        <div class="push-down-15">
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><h2><strong>Section</strong></h2></td>
                <td>
                  <h2><strong>Ethical Framework</strong></h2>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 1 -->
        <div class="push-down-5">
          <table>
            <thead>
              <tr>
                <th><h4><strong>1</strong></h4></th>
                <th><h4><strong>Having the best interest of clients in mind</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>1.1</strong></td>
                <td>
                  The Professional aims to strive towards benefiting those whom they work with and respecting their client’s best interest.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.2</strong></td>
                <td>
                  The Professional undertakes to educate the client about the process of professional practice, to treat clients with respect and doesn’t question their right to freedom of choice and autonomy.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.3</strong></td>
                <td>
                  The Professional undertakes not to enter into a sexual relationship with a client.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.4</strong></td>
                <td>
                  Because a Professional’s professional judgments and actions may influence	the lives of others, they undertake to not exploit and or abuse current or past relationships with clients or use their power for sexual, financial, 	emotional, social, political or organisational gain.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.5</strong></td>
                <td>
                  The Professional may experience behavioural changes outside their professional life and is aware that this may have an effect on the relationship with their clients. Therefore the Professional strives to working to the best of their ability for their client’s benefit, regardless of their personal situation.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.6</strong></td>
                <td>
                  Professionals must be aware of possible implications of dual and multiple relationships with new and existing clients. This refers to a relationship (social or commercial) that runs alongside the professional relationship (examples of this can often be found within small communities). It is the responsibility of the Professional to manage the boundaries and confidentiality of a Professional relationship at all times.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.7</strong></td>
                <td>
                  The Professional undertakes to not rush into a personal or business relationship with a client and should take into account the length of time that has elapsed since a professional relationship has ended.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="10%"><strong>1.8</strong></td>
                <td>
                  The Professional undertakes not to harm their clients and to make every effort in order to ensure that the Ethical Framework and any professional codes of conduct are met during the duration of their professional relationship.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 2 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>2</strong></h4></th>
                <th><h4><strong>Respect for People’s rights, Equality and Diversity</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>2.1</strong></td>
                <td>
                  The Professional is aware of that they will encounter cultural diversity and challenging examples of injustice and inequality as part of their work. It is expected that through professional development and self-evaluation, Professionals will learn to recognise and challenge their own prejudices for the benefit of their relationships with clients.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>2.2</strong></td>
                <td>
                  Professionals must respect the worth and dignity of all people, including their privacy.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>2.3</strong></td>
                <td>
                  The Professional must not discriminate or allow prejudice to affect their 	relationship with clients or colleagues. The Professional must be sensitive to a client’s gender, age, race, colour, language, cultural background, sexual 	orientation, social, immigration or economic status, lifestyle, religion or 	cultural beliefs and consider these factors when working with them.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 3 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>3</strong></h4></th>
                <th><h4><strong>Confidentiality</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>3.1</strong></td>
                <td>
                  In order to establish a trustworthy and genuine Professional relationship, 	the Professional undertakes to respect and protect a client’s confidentiality 	and uphold this by limiting the sharing of personal information, except in exceptional cicrumatances in which a Professional believes beyond  reasonable doubt that a client is in danger, hurting themselves or other persons.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>3.2</strong></td>
                <td>
                  The Professional must notify a client (in writing or verbally) that there are legal 	and ethical implications under which a Professional may disclose sensitive information to a third party. Furthermore, clients must be made aware that their Professional will not use personally or sensitive identifiable information of clients during supervision with their supervisor.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>3.3</strong></td>
                <td>
                  If a judicial or administrative proceeding is carried out where the Professional 	is required by law to attend, they must be aware of the implications this could have on the commitment of confidentiality to a client. It is advisable to seek further legal or ethical advice where appropriate.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>3.4</strong></td>
                <td>
                  The Professional must not use a client as a case study or publish clinical 	material where the client’s anonymity is compromised, unless written consent has been obtained, even if the client has changed their name.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 4 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>4</strong></h4></th>
                <th><h4><strong>Administration </strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>4.1</strong></td>
                <td>
                  The Professional is responsible for updating themselves on knowledge relating to the technology used by the MindBerry platform, which will improve the quality of service.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>4.2</strong></td>
                <td>
                  The Professional is expected to have a back-up plan in the event that the device on which they usually use for MindBerry, fails in the run-up to a session.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>4.3</strong></td>
                <td>
                  The Professional should regularly review and update their profile on 	MindBerry to account for changes in circumstances that may affect their 	professional work on the platform.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>4.4</strong></td>
                <td>
                  The Professional ensures to provide accurate times of absence to MindBerry when they are on holiday or absent for any other reason. Should there be a change of circumstances, the Professional must update their MindBerry profile as soon as possible.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 5 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>5</strong></h4></th>
                <th><h4><strong>Sessions & Records</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>5.1</strong></td>
                <td>
                  Before the start of each online session, the Professional must ensure that they are in a quiet environment where they can concentrate, be fully present and	minimise the possibility of encountering a disturbance from the surrounding envirmonet. This is especially important in the case of audio and video sessions.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>5.2</strong></td>
                <td>
                  It is also the Professional’s responsibility to ensure that they have sufficient power supply and a strong enough interent connection for the duration for their session.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>5.3</strong></td>
                <td>
                  Unless written consent from the client is given, the Professional is not permitted to use a device for voice or video recording, or save a transcript from an instant messaging session.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>5.4</strong></td>
                <td>
                  Professiaonls are allowed and encouraged to make notes (on paper or 	electronically) during and after the session to reflect on the session.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>5.5</strong></td>
                <td>
                  The Professional commits to accurately storing and diminishing personally 	identifiable records or data in order to protect the client’s confidentiality.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>5.6</strong></td>
                <td>
                  The Professional reserves the right to decline a booking made by a client, whether or not it is the client’s first booking, based on the possibility that a client’s need fall outside their area of expertise, or for any other valid reason the Professional may have to decline the booking.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>5.7</strong></td>
                <td>
                  The Professional must not provide services via the MindBerry platform to children under the age of 18 , even if a parent, caregiver or relative is present and or has given their consent.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 6 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>6</strong></h4></th>
                <th><h4><strong>Research & Publications</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>6.1</strong></td>
                <td>
                  Professionals who work in research and write publications are encouraged to continue studying the causes and treatment of mental health issues; to set new standards and push the boundaires of the profession’s knowledge base, examining the conditions that are connected with mental health issues and striving towards a healthier society.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 7 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>7</strong></h4></th>
                <th><h4><strong>Research & Publications</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>7.1</strong></td>
                <td>
                  Professionals can have online supervision via the MindBerry platform using a qualified supervisor or inviting their existing supervisor to join MindBerry 	(subject to the supervisor receiving approval from the MindBerry Team).
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>7.2</strong></td>
                <td>
                  MindBerry encourage members of it’s Professional Network to participate in ongoing training or make use of the services available to them.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>7.3</strong></td>
                <td>
                  It is the Professional’s own responsibility to undergo sufficient supervisory 	hours for personal and professional development to ensure they meet their 	professional obligations to clients.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 8 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>8</strong></h4></th>
                <th><h4><strong>Physical and Mental Health</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>8.1</strong></td>
                <td>
                  The Professional is responsible for abstaining from providing services to a client if they are unable to perform their duties due to the effects of alcohol, drugs or medication.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>8.2</strong></td>
                <td>
                  Each Professional is aware that they must be mindful of their own mental and physical health and must take the appropriate actions if they think their commitment to their client is 	compromised due to a decline in their own mental or physical well-being.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>8.3</strong></td>
                <td>
                  If a Professional is too  physically or mentally impaired  to offer their services, they are obliged to inform their supervisor & clients as soon as possible.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>8.4</strong></td>
                <td>
                  In the rare event of a sudden unavailability, death or illness, a Professional 	must consider beforehand how this can be effectively communicated to 	their clients and suggest appropriate support for clients to deal with this loss.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 9 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>9</strong></h4></th>
                <th><h4><strong>Way of Working</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>9.1</strong></td>
                <td>
                  The Professional agrees to explain their terms to a client or prospective client, including:  their fees,  the length of time over which their services will be delivered, modality, referral process, confidentiality policy, cancellation policy, and any other clarifications that need to be mentioned.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>9.2</strong></td>
                <td>
                  The Professional understands that clients can request from them,	other codes, ethical guidelines which the Professional adheres to and information realting to the procedure for submitting complaints.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>9.3</strong></td>
                <td>
                  It is expected that the Professional responds to the client in a timely manner and provides hope and encouragement.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 10 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>10</strong></h4></th>
                <th><h4><strong>You & Colleagues</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>10.1</strong></td>
                <td>
                  The Professional is encouraged to develop positive working relationships with other colleagues to increase the quality of their service and practice, as well as understand and respect the different modalities practised by other Professionals.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>10.2</strong></td>
                <td>
                  The Professional shall take responsibility for staying up to date on current research and findings in their chosen modality of practice.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 11 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>11</strong></h4></th>
                <th><h4><strong>Consent & Referral</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>11.1</strong></td>
                <td>
                  The Professional is obliged to clarify verifiable consent to their clients: if applicable a Professional’s clinical method(s) of working, the client’s freedom to choose any therapeutic interventions suggested by the Professional and commitments required from each other in a professional relationship.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>11.2</strong></td>
                <td>
                  Before a client participates in a research project, the Professional must set out the nature, rules, purpose, obtaining consent, right to withdrawal, how the client’s contact details will be used and parameters for any further contact (i.e. to communicate results or for help if client has been affected by participation). The Professional must obtain verbal or written consent from the client before commencing any Professional Services or research and undertakes not to intentionally mislead or pressure a client into a particular form of coaching, therapy or research.
                  <br /><br />
                  Should the Professional become unable to continue with a client because their needs are outside the Professional’s scope of competence, they must inform the client and where appropriate offer alternative referrals.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>11.3</strong></td>
                <td>
                  The Professional is not obliged to accept a referral or fill in for a colleague if they think that a given case falls outside of their area of expertise.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>11.4</strong></td>
                <td>
                  If the Professional has changed their mind after accepting a referral, they 	must contact a member of the MindBerry Team immediately.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 12 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>12</strong></h4></th>
                <th><h4><strong>Professional Conduct</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>12.1</strong></td>
                <td>
                  Professional Services can have both positive and negative effects on a client during a session. The Professional recognises these effects and critically examines the impact it has on their Professional Practice, always maintaining their client’s best interest.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>12.2</strong></td>
                <td>
                  If the following changes arise, the Professional agrees to contact MindBerry:
                </td>
              </tr>

              <tr><td><div class="push-down-10"></div></td><td><div class="push-down-10"></div></td></tr>

              <tr>
                <td></td>
                <td>
                  <table>
                    <tr>
                      <td valign="top" width="8%"><strong>A)</strong></td>
                      <td>
                        Conviction of any type of criminal offence or caution.
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td><div class="push-down-10"></div></td><td><div class="push-down-5"></div></td></tr>
              <tr>
                <td></td>
                <td>
                  <table>
                    <tr>
                      <td valign="top" width="8%"><strong>B)</strong></td>
                      <td>
                        Disciplinary action by any membership organisation orprofessional body relating to mental health or social care.
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td><div class="push-down-10"></div></td><td><div class="push-down-5"></div></td></tr>
              <tr>
                <td></td>
                <td>
                  <table>
                    <tr>
                      <td valign="top" width="8%"><strong>C)</strong></td>
                      <td>
                        Any suspension or caution from an employer for concerns relating to practise of coaching, therapy, competence or health.
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>12.3</strong></td>
                <td>
                  If any of the above apply, the Professional must cooperate with any lawful 	investigation or inquiry made which is subject to rules of confidentiality and any other ethical framework adhered to. It is advisable to contact a colleague or 	seek legal advice.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>12.4</strong></td>
                <td>
                  In section 12.2, the Professional accepts that any of these changes may have 	an impact on their Professional Practice. The MindBerry team will consider potential risks to clients and may terminate the Professional’s affiliation with MindBerry.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 13 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>13</strong></h4></th>
                <th><h4><strong>Professional Knowledge & Experience</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>13.1</strong></td>
                <td>
                  The Professional must ensure that all details displayed on their MindBerry profile are accurate and not false, misleading or exaggerated.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>13.2</strong></td>
                <td>
                  The Professional is skilled enough to recognise the boundaries and	limitation of their expertise and ensures to always practice competently.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>13.3</strong></td>
                <td>
                  The Professional is constantly involved in a personal and professional 	development process, commonly known as “Continuing Professional 	Development”.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 14 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>14</strong></h4></th>
                <th><h4><strong>Integrity & Justice</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>14.1</strong></td>
                <td>
                  The Professional seeks the promotion of accuracy, honesty and truthfulness in the practice of coaching and therapy and does not engage in any type of fraud, cheating or poorly defined commitments.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>14.1</strong></td>
                <td>
                  The Professional recognises that people who access and register with   MindBerry are treated fairly and as equals, deserving of a quality service.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 15 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>15</strong></h4></th>
                <th><h4><strong>Indemnity Insurance</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>15.1</strong></td>
                <td>
                  It is the Professional’s responsibility to ensure that they have adequate 	indemnity insurance that covers their professional work. Professionals are obligated to know whether or not their insurance policy covers the continent or country where the client physically resides before engaging with them.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 16 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>16</strong></h4></th>
                <th><h4><strong>Advertisement</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>1.6</strong></td>
                <td>
                  A Professional’s profile can be viewed in preview and full mode on the 	MindBerry platform, or app, by non-members. However , only  registered MindBerry  users are able to book with a Professional. The Professional ensures that any advertisement, social media, product, promotional material and services appearing on their profile or via a link will be handled in a responsible way and must not be untrue, misleading, inaccurate or deceptive. The Professional understands not to create and promote unsubstantiated statements relating to particular 	coaching and therapeutic modalities.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 17 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>17</strong></h4></th>
                <th><h4><strong>Complaints</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>17.1</strong></td>
                <td>
                  It is expected of the Professional to maintain a reasonable level of awareness and understanding of the complaints procedure at MindBerry, including relevant laws and statutory responsibilities which are applied to their 	practice. If a complaint was submitted, the MindBerry team will carry out formal procedures for addressing ethical concerns or misconduct and if 	necessary, resort to revoking the Professional’s MindBerry membership.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 18 -->
        <div class="push-down-25">
          <table>
            <thead>
              <tr>
                <th><h4><strong>18</strong></h4></th>
                <th><h4><strong>Other</strong></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td valign="top" width="20%"><strong>18.1</strong></td>
                <td>
                  During the vetting process, the Professional understands that all 	information submitted must be true and not falsified, edited, obsolete or 	misleading. Any information that can be found to have been compromised 	in their favour in order to secure a membership with MindBerry, will result in the rejection of the Professional’s application or termination of their membership.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>18.2</strong></td>
                <td>
                  The Professional may not publish a client’s feedback on their MindBerry profile without their consent.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>18.3</strong></td>
                <td>
                  For the sake of good practice and adhering to ethical standards, the 	Professional may not engage in online or face to face sessions with their 	current MindBerry client without the booking and use of the MindBerry 	platform for saving money, competition or any other reason.
                </td>
              </tr>

              <tr><td><div class="push-down-20"></div></td><td><div class="push-down-20"></div></td></tr>

              <tr>
                <td valign="top" width="20%"><strong>18.4</strong></td>
                <td>
                  Professionals must respect a client’s decision to book a session with another MindBerry Professional if the original Professional is unavailable due to illness, holiday, emergency or any other reason. At the end of the session with another Professional, for as long as the client is under the care of their original Professional, they are not allowed to book another 	session with a different Professional using the MindBerry platform. This 	condition is no longer relevant if the Professional gives his or her consent for the client to book another session with a different MindBerry Professional.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </section>
