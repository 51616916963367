import { Component } from "@angular/core";

@Component({
  selector: 'blogpost-startup-mental-health',
  templateUrl: './startup-mental-health.component.html',
})

export class BlogPostStartupMentalHealthComponent {
  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
