<div class="banner-blog blog-header">
  <div class="container">
    <div class="blog-banner-content">
      <a class="btn btn-main" routerLink="/home">Back</a>
    </div>
  </div>
</div>

<div class="post-body-wrapper">
  <h1>Start-Up Founders & Mental Health</h1>
  <h2>Written by Sandra de Monte <br /><span>(Integrative Psychotherapist & Founder MindBerry)</span></h2>
  <h3>10/07/2021</h3>

  <div class="post-image">
    <img src="/images/article-images/startup-founders-and-mental-health.jpg" />
  </div>

  <p>
    It is true that being a start-up founder is incredibly stressful. Mental wellbeing is an important factor for every founder. It helps preserve your talents as an innovator and visionary storyteller, and helps you optimise your performance as a leader. As a founder, you must make some tough and challenging decisions at every stage of your business. If you have a good mental wellbeing, you will be able to see beyond.
  </p>
  <p>
    We see more founders working with coaches, which can lift "humanity" up in the VC world. The metrics are important when chasing new rounds of investment, however, there can be too much emphasis on them in the short-term, without looking to the long-term. It needs to be a return on coaching/therapy support mind-set, not just return-on-investment. We have a choice here to create a better future. In the end good health is good business. Investors and VCs need to understand that too.
  </p>
  <p>
    The workplace has forever changed. COVID-19 has put mental health front and centre for businesses as the safety of employees is paramount for survival. Employees are in need and are demanding better coverage. More companies understand this and are providing mental health coverage. Companies are investing much more in making mental health services more accessible in the workplace. Improving wellbeing is a good return on investment as it improves employee outcomes (and certainly reduces the costs for replacing employees) and improves company performance.
  </p>
</div>
